import React, { useState, useEffect } from "react";
import { Button, Container, Modal, Form, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "./Template1.css";
import moment from "moment";
import { toast } from "react-toastify";
import Template1 from "./Templates1";
import axios from "axios";
import * as yup from "yup";
import { useFormik } from "formik";
import Templates2 from "./Templates2";
import Templates3 from "./Templates3";
import Templates4 from "./Templates4";
import Templates5 from "./Templates5";
import { fetchSuggestions } from "../../Utils/AutoComplete";
import { generateInvoiceNumber } from "../../Utils/generateInvoiceNumber";
import Templates6 from "./Templates6";

function SelectTemplete(props) {
  const { state } = useLocation();
  const { theme } = props;
  const { userInfo } = useSelector((state) => state?.userInfo);
  const { invoices } = useSelector((state) => state?.invoices);
  const navigate = useNavigate();
  const isShowTemplate = window.location.pathname.includes("invoiceTemplate");
  const [capitalizedPaidTo, setCapitalizedPaidTo] = useState(true);
  let { id, requestType } = useParams();
  const isCreateInvoice =
    window.location.pathname.includes("create-new-invoice") ||
    requestType === "create-duplicate-invoice";
  const [applyGST, setApplyGST] = useState(false);
  const [applyDIS, setApplyDIS] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [, setValueUpdater] = useState(null);
  const [autoComplete, setAutoComplete] = useState([]);

  const [showModal, setShowModal] = useState({ is: false, msg: "" });
  const validationSchema = yup.object().shape({
    paid_to: yup.string().required("Required field."),
    address: yup.string().required("Required field."),
    payment_mode: yup.string().required("Required field."),
    bill_description: yup.array().of(
      yup.object().shape({
        item_description: yup.string().required("Required field."),
        item_price: yup.string().required("Required field."),
        quantity: yup.string().required("Required field."),
      })
    ),
    payment_mode_info: yup.string().when("payment_mode", {
      is: "Cash",
      then: yup.string().nullable(true),
      otherwise: yup.string().when("payment_mode", {
        is: "Card",
        then: yup
          .string()
          .matches(/^\d{4}$/, "Must be exactly 4 digits")
          .required("Last 4 digits are required."),
        otherwise: yup.string().required("Required field."),
      }),
    }),
  });

  const initialValues = state?.isTemplateChange
    ? state?.values
    : (isCreateInvoice && requestType !== "create-duplicate-invoice") ||
      isShowTemplate
    ? {
        has_gst: 0,
        applyGST: false,
        template_id: theme,
        has_discounts: 0,
        payment_mode: "",
        payment_mode_info: "",
        invoice_no: "",
        paid_to: "",
        address: "",
        user_id: userInfo?.user_data?.id,
        total_amount: "",
        note: "",
        date: moment().format("YYYY-MM-DD"),
        bill_description: [
          {
            item_description: "",
            item_price: "",
            quantity: 1,
          },
        ],
      }
    : {
        ...invoices?.find((item) => item.id === parseInt(id, 10)),
        removedItems: [],
      };
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      if (isCreateInvoice) {
        handleCreateInvoice(formValues);
      } else if (requestType === "edit") {
        handleUpdateInvoice(formValues);
      }
    },
  });

  useEffect(() => {
    if (isCreateInvoice) {
      formik.setFieldValue(
        "invoice_no",
        generateInvoiceNumber(formik?.values?.date)
      );
    }
  }, [formik?.values?.date]);

  const { ToWords } = require("to-words");
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const handlePaidToChange = (e) => {
    const inputValue = e.target.value;
    const capitalizedValue =
      capitalizedPaidTo && inputValue !== ""
        ? inputValue.charAt(0).toUpperCase() + inputValue.slice(1)
        : inputValue;

    formik.setFieldValue("paid_to", capitalizedValue);

    if (capitalizedPaidTo) {
      setCapitalizedPaidTo(false);
    }
  };

  const addFields = () => {
    let newfield = {
      item_description: "",
      item_price: "",
      quantity: 1,
    };
    if (!isCreateInvoice) {
      newfield = { ...newfield, id: null };
    }
    formik.setFieldValue("bill_description", [
      ...formik.values.bill_description,
      newfield,
    ]);
    setValueUpdater(Math.random());
  };

  const removeFields = (index, itemId) => {
    if (requestType === "edit") {
      formik?.setFieldValue("removedItems", [
        ...formik?.values?.removedItems,
        itemId,
      ]);
    }
    // formik.values.bill_description?.splice(index, 1);
    if (
      formik.values.bill_description &&
      Array.isArray(formik.values.bill_description)
    ) {
      formik.values.bill_description.splice(index, 1);
    }
    setValueUpdater(Math.random());
  };

  const handleCreateInvoice = (formValues) => {
    setDisableSubmit(true);
    formValues["invoice_date"] = moment(formValues?.date).format("DD/MM/YYYY");
    formValues["has_gst"] = applyGST ? formValues?.has_gst : 0;
    formValues["has_discounts"] = applyDIS ? formValues?.has_discounts : 0;

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_CREATE_NEW_BILL}`,
        formValues
      )
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Invoice created successfully", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            setDisableSubmit(false);
            navigate("/invoices");
          }, 4000);
        }
      })
      .catch((error) => {
        setDisableSubmit(false);
        setShowModal({
          ...showModal,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.message ||
            error?.message,
        });

        return error;
      });
  };
  const handleUpdateInvoice = (formValues) => {
    setDisableSubmit(true);
    formValues["invoice_date"] = moment(formValues?.date).format("DD/MM/YYYY");
    formValues["has_gst"] = applyGST ? formValues?.has_gst : 0;
    formValues["has_discounts"] = applyDIS ? formValues?.has_discounts : 0;
    formValues["template_id"] =
      formValues?.template_id === undefined || formValues?.template_id === null
        ? 1
        : formValues?.template_id;
    if (formValues?.removedItems?.length === 0) {
      delete formValues?.removedItems;
    }

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_UPDATE_BILL}`,
        formValues
      )
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Invoice updated successfully", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            setDisableSubmit(false);
            navigate("/invoices");
          }, 4000);
        }
      })
      .catch((error) => {
        setDisableSubmit(false);
        setShowModal({
          ...showModal,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.message ||
            error?.message,
        });

        return error;
      });
  };

  const handleClose = () => {
    setShowModal({
      ...showModal,
      is: false,
      msg: "",
    });
  };

  const handleAutoComplete = (e, searching_for, requestType) => {
    const query = e.target.value;
    if (query !== "") {
      fetchSuggestions(query, searching_for, requestType)
        .then((res) => {
          if (res?.status === 200) {
            setAutoComplete(res?.data?.data);
          }
        })
        .catch((error) => {
          setAutoComplete([]);
        });
    } else {
      setAutoComplete([]);
    }
  };
  const renderTooltip = (searching_for) => (
    <Tooltip id="tooltip" style={{ zIndex: 9999 }}>
      <div style={{ maxHeight: "200px", overflowY: "auto" }}>
        <ul
          style={{ listStyleType: "none", textAlign: "left", paddingLeft: 0 }}
        >
          {autoComplete.map((suggestion, index) => (
            <li
              key={index}
              className="my-2"
              style={{
                cursor: "pointer",
                fontWeight: "900px",
                fontSize: "13px",
                margin: "5px",
                transition: "color 0.3s ease",
              }}
              onClick={() => {
                formik?.setFieldValue(`${searching_for}`, suggestion?.value);
                setAutoComplete([]);
              }}
              onMouseEnter={(e) => (e.target.style.color = "#fd5631")}
              onMouseLeave={(e) => (e.target.style.color = "")}
            >
              {suggestion?.value}
            </li>
          ))}
        </ul>
      </div>
    </Tooltip>
  );
  const getTemplateView = (id) => {
    let temp;
    switch (id) {
      case 3:
        temp = (
          <Templates2
            requestType={requestType}
            isCreateInvoice={isCreateInvoice}
            applyGST={applyGST}
            applyDIS={applyDIS}
            toWords={toWords}
            addFields={addFields}
            setApplyGST={setApplyGST}
            setApplyDIS={setApplyDIS}
            disableSubmit={disableSubmit}
            removeFields={removeFields}
            formik={formik}
            showTemplate={isShowTemplate}
            handlePaidToChange={handlePaidToChange}
            handleAutoComplete={handleAutoComplete}
            autoComplete={autoComplete}
            setAutoComplete={setAutoComplete}
            renderTooltip={renderTooltip}
          />
        );
        break;

      case 4:
        temp = (
          <Templates3
            requestType={requestType}
            isCreateInvoice={isCreateInvoice}
            applyGST={applyGST}
            applyDIS={applyDIS}
            toWords={toWords}
            addFields={addFields}
            setApplyGST={setApplyGST}
            setApplyDIS={setApplyDIS}
            disableSubmit={disableSubmit}
            removeFields={removeFields}
            formik={formik}
            showTemplate={isShowTemplate}
            handlePaidToChange={handlePaidToChange}
            handleAutoComplete={handleAutoComplete}
            autoComplete={autoComplete}
            setAutoComplete={setAutoComplete}
            renderTooltip={renderTooltip}
          />
        );
        break;

      case 7:
        temp = (
          <Templates4
            requestType={requestType}
            isCreateInvoice={isCreateInvoice}
            applyGST={applyGST}
            applyDIS={applyDIS}
            toWords={toWords}
            addFields={addFields}
            setApplyGST={setApplyGST}
            setApplyDIS={setApplyDIS}
            disableSubmit={disableSubmit}
            removeFields={removeFields}
            formik={formik}
            showTemplate={isShowTemplate}
            handlePaidToChange={handlePaidToChange}
            handleAutoComplete={handleAutoComplete}
            autoComplete={autoComplete}
            setAutoComplete={setAutoComplete}
            renderTooltip={renderTooltip}
          />
        );
        break;
      case 6:
        temp = (
          <Templates5
            requestType={requestType}
            isCreateInvoice={isCreateInvoice}
            applyGST={applyGST}
            applyDIS={applyDIS}
            toWords={toWords}
            addFields={addFields}
            setApplyGST={setApplyGST}
            setApplyDIS={setApplyDIS}
            disableSubmit={disableSubmit}
            removeFields={removeFields}
            formik={formik}
            showTemplate={isShowTemplate}
            handlePaidToChange={handlePaidToChange}
            handleAutoComplete={handleAutoComplete}
            autoComplete={autoComplete}
            setAutoComplete={setAutoComplete}
            renderTooltip={renderTooltip}
          />
        );
        break;
      case 5:
        temp = (
          <Templates6
            requestType={requestType}
            isCreateInvoice={isCreateInvoice}
            applyGST={applyGST}
            applyDIS={applyDIS}
            toWords={toWords}
            addFields={addFields}
            setApplyGST={setApplyGST}
            setApplyDIS={setApplyDIS}
            disableSubmit={disableSubmit}
            removeFields={removeFields}
            formik={formik}
            showTemplate={isShowTemplate}
            handlePaidToChange={handlePaidToChange}
            handleAutoComplete={handleAutoComplete}
            autoComplete={autoComplete}
            setAutoComplete={setAutoComplete}
            renderTooltip={renderTooltip}
          />
        );
        break;
      default:
        temp = (
          <Template1
            requestType={requestType}
            isCreateInvoice={isCreateInvoice}
            applyGST={applyGST}
            applyDIS={applyDIS}
            toWords={toWords}
            addFields={addFields}
            setApplyGST={setApplyGST}
            setApplyDIS={setApplyDIS}
            disableSubmit={disableSubmit}
            removeFields={removeFields}
            formik={formik}
            showTemplate={isShowTemplate}
            handlePaidToChange={handlePaidToChange}
            handleAutoComplete={handleAutoComplete}
            autoComplete={autoComplete}
            setAutoComplete={setAutoComplete}
            renderTooltip={renderTooltip}
          />
        );
    }

    return temp;
  };
  return (
    <>
      {getTemplateView(parseInt(formik?.values?.template_id, 10))}
      {/* <Template1
        requestType={requestType}
        isCreateInvoice={isCreateInvoice}
        applyGST={applyGST}
        applyDIS={applyDIS}
        toWords={toWords}
        addFields={addFields}
        setApplyGST={setApplyGST}
        setApplyDIS={setApplyDIS}
        disableSubmit={disableSubmit}
        removeFields={removeFields}
        formik={formik}
      /> */}
      <Modal show={showModal?.is} onHide={handleClose} centered>
        <Modal.Header className="d-flex justify-content-center border-0">
          <Modal.Title style={{ color: "black" }}>Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            color: "black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {showModal?.msg}{" "}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            className="closeButton"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SelectTemplete;
