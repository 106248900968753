import { React, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { CiBoxList } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import Nav from "react-bootstrap/Nav";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { CgProfile } from "react-icons/cg";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { RiLogoutCircleLine } from "react-icons/ri";
import { onLogout } from "../../store/Auth/AuthActions";

const TopNavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userInfo);
  const user = Object.keys(userInfo).length && userInfo?.user_data;
  const userName = user && `${user.firstname} ${user?.lastname}`;

  useEffect(() => {
    if (window.innerWidth <= 992) {
      document.body.classList.add("sidenav-toggled");
    }

    // Enable tooltips globally
    const tooltipTriggerList = Array.from(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );

    tooltipTriggerList.map((tooltipTriggerEl) => {
      return new window.bootstrap.Tooltip(tooltipTriggerEl);
    });

    // Enable popovers globally
    const popoverTriggerList = Array.from(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    popoverTriggerList.map((popoverTriggerEl) => {
      return new window.bootstrap.Popover(popoverTriggerEl);
    });

    // Toggle the side navigation
    const sidebarToggle = document.body.querySelector("#sidebarToggle");
    if (sidebarToggle) {
      sidebarToggle.addEventListener("click", (event) => {
        event.preventDefault();
        document.body.classList.toggle("sidenav-toggled");
        localStorage.setItem(
          "sb|sidebar-toggle",
          document.body.classList.contains("sidenav-toggled")
        );
      });
    }

    // Add active state to sidebar nav links
    let activatedPath = window.location.pathname.match(/([\w-]+\.html)/, "$1");

    if (activatedPath) {
      activatedPath = activatedPath[0];
    } else {
      activatedPath = "index.html";
    }

    const targetAnchors = Array.from(
      document.querySelectorAll('[href="' + activatedPath + '"].nav-link')
    );

    targetAnchors.forEach((targetAnchor) => {
      let parentNode = targetAnchor.parentNode;
      while (parentNode !== null && parentNode !== document.documentElement) {
        if (parentNode.classList.contains("collapse")) {
          parentNode.classList.add("show");
          const parentNavLink = document.body.querySelector(
            '[data-bs-target="#' + parentNode.id + '"]'
          );
          parentNavLink.classList.remove("collapsed");
          parentNavLink.classList.add("active");
        }
        parentNode = parentNode.parentNode;
      }
      targetAnchor.classList.add("active");
    });
  }, []);

  function getCurrentDimension() {
    if (window.innerWidth < 968) {
      if (!document.body.classList.contains("sidenav-toggled")) {
        document.body.classList.add("sidenav-toggled");
      }
    } else {
      document.body.classList.remove("sidenav-toggled");
    }
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const logOut = () => {
    dispatch(onLogout());
    navigate("/login", { replace: true });
  };
  const profile = () => {
    navigate("/profile");
  };
  useEffect(() => {
    window.addEventListener("resize", getCurrentDimension);
    return () => {
      window.removeEventListener("resize", getCurrentDimension);
    };
  }, []);
  const account_preferences = () => {
    navigate("/account_preferences");
  };

  return (
    <>
      <Nav
        className="position-fixed top-0 bg-white p-1 px-2 w-100 shadow-sm  navbar navbar-expand justify-content-between justify-content-sm-start  shadow-none border-bottom"
        style={{ zIndex: 2 }}
      >
        <div style={{ width: 200, minHeight: 55 }}>
          <Link to={"/"} className="navbar-brand">
            {userInfo?.account_preferences?.attached_logo ? (
              <img
                alt="temp"
                src={`
                    ${process.env.REACT_APP_API_BASE_URL_IMAGE}${userInfo?.account_preferences?.attached_logo}
                  `}
                style={{ maxHeight: 55 }}
              />
            ) : (
              "{Logo}"
            )}
            {/* <img src="/images/logoiph.png" alt="logo" style={{ height: 55 }} /> */}
          </Link>
        </div>

        <Button
          variant="secondary"
          className="btn btn-sm btn-icon order-1 order-lg-0 me-lg-2 mx-2"
          id="sidebarToggle"
        >
          <FiMenu size={24} />
        </Button>

        <div className=" navbar-nav align-items-center ms-auto d-none d-sm-block">
          <Dropdown>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 200 }}
              overlay={<Tooltip id="button-tooltip">Account</Tooltip>}
            >
              <Dropdown.Toggle
                as="text"
                variant="success"
                style={{ cursor: "pointer" }}
              >
                {userName}
              </Dropdown.Toggle>
            </OverlayTrigger>

            <Dropdown.Menu style={{ width: "200px" }}>
              <Dropdown.Item
                onClick={profile}
                className="d-flex align-items-center"
              >
                <CgProfile size={18} /> <div className="ps-2">My Profile</div>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={account_preferences}
                className="d-flex align-items-center"
              >
                <CiBoxList size={18} />
                <div className="ps-2">Account Preferences</div>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={logOut}
                className="d-flex align-items-center"
              >
                <RiLogoutCircleLine size={18} />
                <div className="ps-2">Log Out</div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Nav>
    </>
  );
};

export default TopNavBar;
