import axios from "axios";

export const fetchSuggestions = async (query, searching_for, requestType) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_AUTO_COMPLETE}`,
      {
        requestType,
        searching_for,
        query,
      }
    );
    return response; // Return response data
  } catch (error) {
    throw error; // Rethrow error to be handled by the caller
  }
};
