import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Nopage from "./pages/Nopage";
import Profile from "./pages/Profile1";
import AllTemplate from "./components/Invoice/AllTemplate";
import UpdateProfile from "./pages/UpdateProfile";
import CreateNewUser from "./pages/CreateNewUser";
import Users from "./pages/Users";
import "react-datepicker/dist/react-datepicker.css";
import ChangePassword from "./pages/ChangePassword";
import TestCompo from "./pages/testcompo";
import Dashboard from "./pages/Dashboard";
import TopNavBar from "./components/NavBar/TopNavBar";
import LeftNavBar from "./components/NavBar/LeftNavBar";
import Roles from "./pages/Roles";
import CashBook from "./pages/CashBook";
import SelectTemplete from "./components/Invoice/SelectInvoice";
import KhataBook from "./pages/KhataBook";
import AllVouchers from "./pages/AllVouchers";
import AllPreferences from "./pages/AllPreferences";
import VoucherTemplates from "./components/Voucher/VoucherTemplates";
import SelectVouchers from "./components/Voucher/SelectVouchers";
import InformativeBanner from "./components/InformativeBanner";
import Media from "./pages/Media";

function App() {
  const { userInfo, theme } = useSelector((state) => state);
  const user =
    Object.keys(userInfo.userInfo).length && userInfo?.userInfo?.user_data;
  return Object.keys(user).length ? (
    <>
      <TopNavBar />
      <main className="d-flex flex-row">
        <LeftNavBar />
        <div
          className="container-lg pt-4 pb-5"
          style={{ minHeight: "calc(100vh - 63px)" }}
        >
          <InformativeBanner />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Dashboard users={user} />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/account_preferences" element={<AllPreferences />} />
            <Route path="/invoices" element={<Home />} />
            <Route path="/vouchers" element={<AllVouchers />} />

            <Route
              path="/create-new-invoice"
              element={<SelectTemplete theme={theme.InvoiceTemplate} />}
            />
            <Route path="/user-roles" element={<Roles />} />
            <Route
              path="/invoices/:requestType/:id"
              element={<SelectTemplete theme={theme.InvoiceTemplate} />}
            />
            <Route path="/create-new-user" element={<CreateNewUser />} />
            <Route
              path="/create-new-voucher"
              element={<SelectVouchers theme={theme.VoucherTemplate} />}
            />
            <Route
              path="/vouchers/:requestType/:id"
              element={<SelectVouchers theme={theme.VoucherTemplate} />}
            />
            <Route path="/invoiceTemplate" element={<AllTemplate />} />
            <Route path="/voucherTemplate" element={<VoucherTemplates />} />
            <Route path="/allTemplate" element={<AllTemplate />} />
            <Route path="/updateprofile" element={<UpdateProfile />} />
            <Route path="/users" element={<Users />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/*" element={<Nopage />} />
            <Route path="/testghug" element={<TestCompo />} />
            <Route path="cashbook" element={<CashBook />} />
            <Route path="khatabook" element={<KhataBook />} />
            <Route path="media" element={<Media />} />
          </Routes>
        </div>
      </main>
    </>
  ) : (
    <Login />
  );
}
export default App;
