import React, { useState, useEffect } from "react";
import { Form, Button, Card, Row, Col, InputGroup } from "react-bootstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { SET_REFRESH_DATA_TABLE } from "../store/Theme/ThemeActionTypes";
import { RxCrossCircled } from "react-icons/rx";
import Head from "../components/NavBar/Head";
import Modals from "../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Location } from "../Utils/Locations";
import { RiPencilFill } from "react-icons/ri";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { handleAccountPreferences } from "../store/Auth/AuthActions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

export default function AllPreferences() {
  const [image, setImage] = useState(false);
  const [editableAddress, setEditableAddress] = useState(true);
  const [editableContact, setEditableContact] = useState(true);
  const [editableDateFormat, setEditableDateFormat] = useState(true);
  const [editableTrademark, setEditableTrademark] = useState(true);
  const [showPopup, setShowPopup] = useState({ is: false, msg: "" });

  const [cities, setCities] = useState([]);

  const { userInfo } = useSelector((state) => state?.userInfo);
  const dispatch = useDispatch();
  const [checkValidation, setCheckValidation] = useState("");
  const [selectedOption, setSelectedOption] = useState(
    userInfo?.account_preferences?.date_formate
      ? userInfo?.account_preferences?.date_formate
      : "DD MMM, YYYY"
  );

  const addressValidationSchema = Yup.object().shape({
    address: Yup.string().required("Address is required").nullable(),
    country: Yup.string()
      .required("Country is required")
      .nullable()
      .matches(/^[a-zA-Z ]+$/, "Only alphabetic characters allowed"),
    state: Yup.string().required("State is required").nullable(),
    city: Yup.string().required("City is required").nullable(),
    pincode: Yup.string()
      .required("Pincode is required")
      .nullable()
      .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/gm, "Pincode is not valid"),
  });

  const contactValidationSchema = Yup.object().shape({
    contact_no: Yup.string()
      .required("Contact number is required")
      .test({
        test: (value) => !value || /^\d{10}$/.test(value),
        message: "Contact number must be 10 digits",
      }),
    alt_contact_no: Yup.string()
      .nullable()
      .test({
        test: (value) => !value || /^\d{10}$/.test(value),
        message: "Alternate Contact number must be 10 digits",
      }),
    landline: Yup.string().nullable(),
    email_address: Yup.string()
      .email("Please enter a valid email address")
      .required("Email address is required"),
    alt_email_address: Yup.string()
      .nullable()
      .email("Please enter a valid email address"),
  });

  const tradeMarkValidation = Yup.object().shape({
    attached_logo: Yup.string().nullable(),
  });
  const dateValidationSchema = Yup.object().shape({
    date_formate: Yup.string().nullable(),
  });
  const handlePopupClose = () => {
    setShowPopup({
      ...showPopup,
      is: false,
      msg: "",
    });
  };
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(
      checkValidation === "addr"
        ? addressValidationSchema
        : checkValidation === "contact"
        ? contactValidationSchema
        : checkValidation === "trademark"
        ? tradeMarkValidation
        : dateValidationSchema
    ),
  });

  useEffect(() => {
    if (
      userInfo?.account_preferences?.state !== null &&
      userInfo?.account_preferences?.state !== undefined
    ) {
      setCities(Location[`${userInfo?.account_preferences?.state}`]);
    }
  }, [userInfo?.account_preferences, editableAddress]);

  const createOrUpdateAccountEntry = async (data) => {
    const payload = userInfo?.account_preferences?.id
      ? {
          ...data,
          user_id: userInfo?.user_data?.id,
          id: userInfo?.account_preferences?.id,
        }
      : { ...data, user_id: userInfo?.user_data?.id };

    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_UPDATE_ACCOUNT}`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            handleAccountPreferences({
              ...userInfo,
              account_preferences: response?.data?.data,
            })
          );
          reset();
          setEditableContact(true);
          setEditableAddress(true);
          setEditableDateFormat(true);
          setEditableTrademark(true);
          toast.success("Account preferences updated successfully", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
          reset();
          return response;
        }
      })
      .catch((error) => {
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.response?.data?.error ||
            error?.data?.error ||
            error?.message,
        });

        return error;
      });
  };

  const handleImageUpload = (file) => {
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImage(reader.result);
        // setValue("attached_logo", base64String);
      };
      reader.onload = (event) => {
        const base64String = event.target.result;
        setValue("attached_logo", base64String);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleImageUpload(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    handleImageUpload(file);
    // setValue("attached_logo", base64Image);
  };
  const handleSelect = (option) => {
    setSelectedOption(option);
    setValue("date_formate", option);
  };
  const handleSelectState = (option) => {
    setValue("state", option.target.value);
    setCities(Location[`${option.target.value}`]);
  };

  function numberonly(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9]/.test(ch)) {
      evt.preventDefault();
    }
  }

  return (
    <div>
      <Head title="Account Preferences" />
      <Form>
        <Card className="mb-5">
          <Card.Body>
            <Row className="mb-0">
              <Col>
                <p className="fw-bold fs-6 mb-1">Address</p>
              </Col>
              <Col className="text-end">
                {editableAddress ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Click to edit the form
                      </Tooltip>
                    }
                  >
                    <RiPencilFill
                      className={"fs-4 text-primary"}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        reset();
                        setCheckValidation("addr");
                        setEditableAddress(!editableAddress);
                        setEditableContact(true);
                        setEditableDateFormat(true);
                        setEditableTrademark(true);
                        userInfo?.account_preferences &&
                          Object.entries(userInfo?.account_preferences).forEach(
                            ([key, value]) => {
                              if (
                                key === "address" ||
                                key === "country" ||
                                key === "state" ||
                                key === "city" ||
                                key === "pincode"
                              ) {
                                setValue(key, value);
                              }
                            }
                          );
                      }}
                    />
                  </OverlayTrigger>
                ) : (
                  ""
                )}
              </Col>
              <hr className="mb-3" />
              <Col md={6} sm={12}>
                <Form.Label
                  htmlFor="address"
                  className="mb-0"
                  style={{ color: "#666276" }}
                >
                  Address
                </Form.Label>
                {editableAddress ? (
                  <Form.Text>
                    <p style={{ fontSize: "15px" }}>
                      {userInfo?.account_preferences?.address ? (
                        userInfo?.account_preferences?.address
                      ) : (
                        <>&#8212;</>
                      )}
                    </p>
                  </Form.Text>
                ) : (
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="address"
                      placeholder="Company address: Street, Suite, Landmark.."
                      className="rounded"
                      style={{ color: "#666276" }}
                      readOnly={editableAddress}
                      {...register("address")}
                      isInvalid={!!errors.address?.message}
                      onChange={(e) => setValue("address", e.target.value)}
                    />
                    {errors?.address && (
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {errors?.address?.message}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                )}
              </Col>
              <Col md={3} sm={12}>
                <Form.Label
                  htmlFor="country"
                  className="mb-0"
                  style={{ color: "#666276" }}
                >
                  Country
                </Form.Label>
                {editableAddress ? (
                  <Form.Text>
                    <p style={{ fontSize: "15px" }}>
                      {userInfo?.account_preferences?.country ? (
                        userInfo?.account_preferences?.country
                      ) : (
                        <>&#8212;</>
                      )}
                    </p>
                  </Form.Text>
                ) : (
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="country"
                      placeholder="Country"
                      defaultValue="India"
                      className="rounded"
                      readOnly={editableAddress}
                      style={{ fontSize: "15px", color: "#666276" }}
                      onChange={(e) => setValue("country", e.target.value)}
                      {...register("country")}
                      isInvalid={!!errors.country?.message}
                    />
                    {errors?.country &&
                      errors?.country?.message !== undefined && (
                        <Form.Control.Feedback
                          className="d-block "
                          type="invalid"
                        >
                          {errors?.country?.message}
                        </Form.Control.Feedback>
                      )}
                  </InputGroup>
                )}
              </Col>
              <Col md={3} sm={12}>
                <Form.Label
                  htmlFor="city"
                  className="mb-0"
                  style={{ color: "#666276" }}
                >
                  State
                </Form.Label>
                {editableAddress ? (
                  <Form.Text>
                    <p style={{ fontSize: "15px" }}>
                      {userInfo?.account_preferences?.state ? (
                        userInfo?.account_preferences?.state
                      ) : (
                        <>&#8212;</>
                      )}
                    </p>
                  </Form.Text>
                ) : (
                  <InputGroup className="mb-3">
                    <Form.Select
                      className="w-auto rounded"
                      value={watch("state")}
                      onChange={handleSelectState}
                      disabled={editableAddress}
                      isInvalid={!!errors.state?.message}
                    >
                      {/* <option value="">
                        {userInfo?.account_preferences?.state === null ||
                        userInfo?.account_preferences?.state === undefined
                          ? "---Select your state---"
                          : userInfo?.account_preferences?.state}
                      </option> */}
                      <option value="">---Select your state---</option>
                      {Location &&
                        Object.keys(Location).map((item, index) => (
                          <option value={item}>{item}</option>
                        ))}
                    </Form.Select>
                    {errors?.state && errors?.state?.message !== undefined && (
                      <Form.Control.Feedback
                        className="d-block "
                        type="invalid"
                      >
                        {errors?.state?.message}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={12}>
                <Form.Label
                  htmlFor="city"
                  className="mb-0"
                  style={{ color: "#666276" }}
                >
                  City
                </Form.Label>
                {editableAddress ? (
                  <Form.Text>
                    <p style={{ fontSize: "15px" }}>
                      {userInfo?.account_preferences?.city ? (
                        userInfo?.account_preferences?.city
                      ) : (
                        <>&#8212;</>
                      )}
                    </p>
                  </Form.Text>
                ) : (
                  <InputGroup className="mb-3">
                    <Form.Select
                      className="w-auto rounded"
                      value={watch("city")}
                      onChange={(e) => setValue("city", e.target.value)}
                      disabled={editableAddress}
                      isInvalid={!!errors.city?.message}
                    >
                      {/* <option value="">
                        {" "}
                        {userInfo?.account_preferences?.city === null ||
                        userInfo?.account_preferences?.city === undefined
                          ? "---Select your city---"
                          : userInfo?.account_preferences?.city}
                      </option> */}
                      <option value="">---Select your city---</option>
                      {cities?.map((item, index) => (
                        <option value={item}>{item}</option>
                      ))}
                    </Form.Select>
                    {errors?.city && errors?.city?.message !== undefined && (
                      <Form.Control.Feedback
                        className="d-block "
                        type="invalid"
                      >
                        {errors?.city?.message}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                )}
              </Col>
              <Col md={3} sm={12}>
                <Form.Label
                  htmlFor="pincode"
                  className="mb-0"
                  style={{ color: "#666276" }}
                >
                  Pincode
                </Form.Label>
                {editableAddress ? (
                  <Form.Text>
                    <p style={{ fontSize: "15px" }}>
                      {userInfo?.account_preferences?.pincode ? (
                        userInfo?.account_preferences?.pincode
                      ) : (
                        <>&#8212;</>
                      )}
                    </p>
                  </Form.Text>
                ) : (
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="pincode"
                      placeholder="Pincode"
                      className="rounded"
                      readOnly={editableAddress}
                      onKeyPress={numberonly}
                      style={{ fontSize: "15px", color: "#666276" }}
                      onChange={(e) => setValue("pincode", e.target.value)}
                      {...register("pincode")}
                      isInvalid={!!errors.pincode?.message}
                    />
                    {errors?.pincode &&
                      errors?.pincode?.message !== undefined && (
                        <Form.Control.Feedback
                          className="d-block "
                          type="invalid"
                        >
                          {errors?.pincode?.message}
                        </Form.Control.Feedback>
                      )}
                  </InputGroup>
                )}
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer
            className={`d-flex justify-content-end ${
              editableAddress ? "d-none" : "d-block"
            }`}
          >
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip">Cancel the changes</Tooltip>
              }
            >
              <Button
                className="me-3 btn-sm"
                variant="secondary"
                onClick={() => {
                  setEditableAddress(!editableAddress);
                  reset();
                  setCheckValidation("");
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tooltip">Save the entry</Tooltip>}
            >
              <Button
                className="btn-sm"
                type="Submit"
                variant="primary"
                onClick={handleSubmit(createOrUpdateAccountEntry)}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </OverlayTrigger>
          </Card.Footer>
        </Card>
      </Form>
      <Form>
        <Card className="mb-5">
          <Card.Body>
            <Row>
              <Col>
                <p className="fw-bold fs-6 mb-1">Contact</p>
              </Col>
              <Col className="text-end">
                {editableContact ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Click to edit the form
                      </Tooltip>
                    }
                  >
                    <RiPencilFill
                      className={"fs-4 text-primary"}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        reset();
                        setCheckValidation("contact");
                        setEditableContact(!editableContact);
                        setEditableAddress(true);
                        setEditableDateFormat(true);
                        setEditableTrademark(true);
                        userInfo?.account_preferences &&
                          Object.entries(userInfo?.account_preferences).forEach(
                            ([key, value]) => {
                              if (
                                key === "contact_no" ||
                                key === "alt_contact_no" ||
                                key === "landline" ||
                                key === "email_address" ||
                                key === "alt_email_address"
                              ) {
                                setValue(key, value);
                              }
                            }
                          );
                      }}
                    />
                  </OverlayTrigger>
                ) : (
                  ""
                )}
              </Col>
              <hr className="mb-3" />
              <Col md={4} sm={12}>
                <Form.Label
                  htmlFor="contact"
                  className="mb-0"
                  style={{ color: "#666276" }}
                >
                  Contact Number
                </Form.Label>
                {editableContact ? (
                  <Form.Text>
                    <p style={{ fontSize: "15px" }}>
                      {userInfo?.account_preferences?.contact_no ? (
                        userInfo?.account_preferences?.contact_no
                      ) : (
                        <>&#8212;</>
                      )}
                    </p>
                  </Form.Text>
                ) : (
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="contact"
                      placeholder="91xxxxxx35"
                      className="rounded"
                      onKeyPress={numberonly}
                      readOnly={editableContact}
                      style={{ fontSize: "15px", color: "#666276" }}
                      {...register("contact_no")}
                      onChange={(e) => setValue("contact_no", e.target.value)}
                      isInvalid={!!errors.contact_no?.message}
                    />
                    {errors?.contact_no &&
                      errors?.contact_no?.message !== undefined && (
                        <Form.Control.Feedback
                          className="d-block "
                          type="invalid"
                        >
                          {errors?.contact_no?.message}
                        </Form.Control.Feedback>
                      )}
                  </InputGroup>
                )}
              </Col>
              <Col md={4} sm={12}>
                <Form.Label
                  htmlFor="alt_contact_no"
                  className="mb-0"
                  style={{ color: "#666276" }}
                >
                  Alternate Contact Number
                </Form.Label>
                {editableContact ? (
                  <Form.Text>
                    <p style={{ fontSize: "15px" }}>
                      {userInfo?.account_preferences?.alt_contact_no ? (
                        userInfo?.account_preferences?.alt_contact_no
                      ) : (
                        <>&#8212;</>
                      )}
                    </p>
                  </Form.Text>
                ) : (
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="alt_contact_no"
                      placeholder="91xxxxxx87"
                      onKeyPress={numberonly}
                      readOnly={editableContact}
                      style={{ fontSize: "15px", color: "#666276" }}
                      isInvalid={!!errors.alt_contact_number?.message}
                      onChange={(e) =>
                        setValue("alt_contact_no", e.target.value)
                      }
                      {...register("alt_contact_no")}
                    />
                    {errors?.alt_contact_no &&
                      errors?.alt_contact_no?.message !== undefined && (
                        <Form.Control.Feedback
                          className="d-block "
                          type="invalid"
                        >
                          {errors?.alt_contact_no?.message}
                        </Form.Control.Feedback>
                      )}
                  </InputGroup>
                )}
              </Col>
              <Col md={4} sm={12}>
                <Form.Label
                  htmlFor="landline"
                  className="mb-0"
                  style={{ color: "#666276" }}
                >
                  Landline
                </Form.Label>
                {editableContact ? (
                  <Form.Text>
                    <p style={{ fontSize: "15px" }}>
                      {userInfo?.account_preferences?.landline ? (
                        userInfo?.account_preferences?.landline
                      ) : (
                        <>&#8212;</>
                      )}
                    </p>
                  </Form.Text>
                ) : (
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="landline"
                      placeholder="(0522)-12345678"
                      onKeyPress={numberonly}
                      readOnly={editableContact}
                      onChange={(e) => setValue("landline", e.target.value)}
                      style={{ fontSize: "15px", color: "#666276" }}
                    />
                    {errors?.landline &&
                      errors?.landline?.message !== undefined && (
                        <Form.Control.Feedback
                          className="d-block "
                          type="invalid"
                        >
                          {errors?.landline?.message}
                        </Form.Control.Feedback>
                      )}
                  </InputGroup>
                )}
              </Col>
            </Row>
            <Row className="">
              <Col md={6} sm={12}>
                <Form.Label
                  htmlFor="email_address"
                  className="mb-0"
                  style={{ color: "#666276" }}
                >
                  Email Address
                </Form.Label>
                {editableContact ? (
                  <Form.Text>
                    <p style={{ fontSize: "15px" }}>
                      {userInfo?.account_preferences?.email_address ? (
                        userInfo?.account_preferences?.email_address
                      ) : (
                        <>&#8212;</>
                      )}
                    </p>
                  </Form.Text>
                ) : (
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="email_address"
                      type="email"
                      className="rounded"
                      placeholder="example@domain.com"
                      readOnly={editableContact}
                      style={{ fontSize: "15px", color: "#666276" }}
                      {...register("email_address")}
                      isInvalid={!!errors.email_address?.message}
                      onChange={(e) =>
                        setValue("email_address", e.target.value)
                      }
                    />
                    {errors?.email_address &&
                      errors?.email_address?.message !== undefined && (
                        <Form.Control.Feedback
                          className="d-block "
                          type="invalid"
                        >
                          {errors?.email_address?.message}
                        </Form.Control.Feedback>
                      )}
                  </InputGroup>
                )}
              </Col>
              <Col md={6} sm={12}>
                <Form.Label
                  htmlFor="alt_email_address"
                  className="mb-0"
                  style={{ color: "#666276" }}
                >
                  Alternate Email Address
                </Form.Label>
                {editableContact ? (
                  <Form.Text>
                    <p style={{ fontSize: "15px" }}>
                      {userInfo?.account_preferences?.alt_email_address ? (
                        userInfo?.account_preferences?.alt_email_address
                      ) : (
                        <>&#8212;</>
                      )}
                    </p>
                  </Form.Text>
                ) : (
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="alt_email_address"
                      placeholder="example@domain.com"
                      readOnly={editableContact}
                      {...register("alt_email_address")}
                      isInvalid={!!errors.alt_email?.message}
                      onChange={(e) =>
                        setValue("alt_email_address", e.target.value)
                      }
                    />
                    {errors?.alt_email_address &&
                      errors?.alt_email_address?.message !== undefined && (
                        <Form.Control.Feedback
                          className="d-block "
                          type="invalid"
                        >
                          {errors?.alt_email_address?.message}
                        </Form.Control.Feedback>
                      )}
                  </InputGroup>
                )}
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer
            className={`d-flex justify-content-end ${
              editableContact ? "d-none" : "d-block"
            }`}
          >
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip">Cancel the changes</Tooltip>
              }
            >
              <Button
                className="me-3 btn-sm"
                variant="secondary"
                onClick={() => {
                  setEditableContact(!editableContact);
                  reset();
                  setCheckValidation("");
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tooltip">Save the entry</Tooltip>}
            >
              <Button
                className="btn-sm"
                type="Submit"
                variant="primary"
                onClick={handleSubmit(createOrUpdateAccountEntry)}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </OverlayTrigger>
          </Card.Footer>
        </Card>
      </Form>
      <Form>
        <Card className="mb-5">
          <Card.Body>
            <Row className="mb-3">
              <Col>
                <p className="fw-bold fs-6 mb-1">Date Format</p>
              </Col>
              <Col className="text-end">
                {editableDateFormat ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Click to edit the form
                      </Tooltip>
                    }
                  >
                    {
                      <RiPencilFill
                        className={"fs-4 text-primary"}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          reset();

                          setEditableDateFormat(!editableDateFormat);
                          setEditableAddress(true);
                          setEditableContact(true);
                          setEditableTrademark(true);
                          userInfo?.account_preferences &&
                            Object.entries(
                              userInfo?.account_preferences
                            ).forEach(([key, value]) => {
                              if (key === "date_formate") {
                                setValue(key, value);
                              }
                            });
                        }}
                      />
                    }
                  </OverlayTrigger>
                ) : (
                  ""
                )}
              </Col>
              <hr className="mb-3" />
              <Col md={6} sm={12}>
                <Form.Label
                  htmlFor="date"
                  style={{ color: "#666276", marginTop: "8px" }}
                >
                  <span className="fs-7 fw-bold mb-0">
                    Select the Date Format
                  </span>

                  <span style={{ fontSize: "13px" }}>
                    &nbsp;(Changes may apply for the entire site)
                  </span>
                </Form.Label>
              </Col>
              <Col md={6} sm={12}>
                {editableDateFormat ? (
                  <Form.Text>
                    <p className="mt-2" style={{ fontSize: "15px" }}>
                      {userInfo?.account_preferences?.date_formate
                        ? userInfo?.account_preferences?.date_formate
                        : "DD MMM, YYYY"}
                    </p>
                  </Form.Text>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Select the date format
                      </Tooltip>
                    }
                  >
                    <Form.Select
                      className="w-auto text-center"
                      value={watch("date_formate") || selectedOption}
                      onChange={(e) => handleSelect(e.target.value)}
                      disabled={editableDateFormat}
                    >
                      <option value="DD MMM, YYYY">DD MMM, YYYY</option>
                      <option value="DD/MM/YYYY">DD / MM / YYYY</option>
                      <option value="YYYY-MM-DD"> YYYY - MM - DD</option>
                    </Form.Select>
                  </OverlayTrigger>
                )}
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer
            className={`d-flex justify-content-end ${
              editableDateFormat ? "d-none" : "d-block"
            }`}
          >
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip">Cancel the changes</Tooltip>
              }
            >
              <Button
                className="me-3 btn-sm"
                variant="secondary"
                onClick={() => {
                  setEditableDateFormat(!editableDateFormat);
                  setCheckValidation("");
                  reset();
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tooltip">Save the entry</Tooltip>}
            >
              <Button
                className="btn-sm"
                type="Submit"
                variant="primary"
                onClick={handleSubmit(createOrUpdateAccountEntry)}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </OverlayTrigger>
          </Card.Footer>
        </Card>
      </Form>
      <Form>
        <Card className="mb-4">
          <Card.Body>
            <Row>
              <Col>
                <p className="fw-bold fs-6 mb-1">Trademark</p>
              </Col>
              <Col className="text-end">
                {editableTrademark ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Click to edit the form
                      </Tooltip>
                    }
                  >
                    <RiPencilFill
                      className={"fs-4 text-primary"}
                      onClick={() => {
                        reset();
                        setEditableTrademark(!editableTrademark);
                        setCheckValidation("trademark");
                        setEditableAddress(true);
                        setEditableContact(true);
                        setEditableDateFormat(true);

                        if (
                          userInfo?.account_preferences?.attached_logo !== "" &&
                          userInfo?.account_preferences?.attached_logo !==
                            undefined
                        ) {
                          setValue("attached_logo", "");
                          setImage(
                            userInfo?.account_preferences?.attached_logo !==
                              null &&
                              userInfo?.account_preferences?.attached_logo !==
                                undefined
                              ? `${process.env.REACT_APP_API_BASE_URL_IMAGE}${userInfo?.account_preferences?.attached_logo}`
                              : false
                          );
                        }
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </OverlayTrigger>
                ) : (
                  ""
                )}
              </Col>
              <hr className="mb-3" />
              <Col md={12}>
                <div
                  style={{
                    // width: "100%",
                    textAlign: "start",
                  }}
                >
                  <label htmlFor="input-file" id="drop-area">
                    <input
                      type="file"
                      accept="image/x-png,image/jpg,image/jpeg"
                      id="input-file"
                      onChange={handleFileInput}
                      hidden
                      disabled={editableTrademark}
                    />
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "5px",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        cursor: "pointer",
                      }}
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                    >
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id="button-tooltip">Logo image</Tooltip>
                        }
                      >
                        {editableTrademark ? (
                          userInfo?.account_preferences?.attached_logo !==
                            null &&
                          userInfo?.account_preferences?.attached_logo !==
                            undefined ? (
                            <img
                              alt="Logo"
                              src={`${process.env.REACT_APP_API_BASE_URL_IMAGE}${userInfo?.account_preferences?.attached_logo}`}
                              width={150}
                              height={150}
                            />
                          ) : (
                            <p>No Image.</p>
                          )
                        ) : (
                          <div>
                            {" "}
                            {/* Wrap the elements in a single parent element */}
                            <img
                              style={{
                                backgroundColor: "#F9F9F9",
                                borderRadius: "5px",
                              }}
                              className={image ? "" : "p-4"}
                              src={
                                image
                                  ? `${image}`
                                  : "https://cdn-icons-png.flaticon.com/256/20/20829.png"
                              }
                              alt="logoImg"
                              width={image ? 150 : 100}
                              height={image ? 150 : 100}
                            />
                            <p
                              className="mt-1 mb-0"
                              style={{ fontSize: "13px" }}
                            >
                              Drag and drop or Click here to upload logo
                            </p>
                          </div>
                        )}
                      </OverlayTrigger>
                    </div>
                  </label>
                  <RxCrossCircled
                    className={`mt-0 ${
                      image && !editableTrademark ? "d-block" : "d-none"
                    } `}
                    style={{ cursor: "pointer" }}
                    onClick={() => setImage(null)}
                    size={25}
                  />
                </div>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer
            className={`d-flex justify-content-end ${
              editableTrademark ? "d-none" : "d-block"
            }`}
          >
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip">Cancel the changes</Tooltip>
              }
            >
              <Button
                className="me-3 btn-sm"
                variant="secondary"
                onClick={() => {
                  setEditableTrademark(!editableTrademark);
                  setCheckValidation("");
                  reset();
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tooltip">Save the entry</Tooltip>}
            >
              <Button
                className="btn-sm"
                type="Submit"
                variant="primary"
                onClick={handleSubmit(createOrUpdateAccountEntry)}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </OverlayTrigger>
          </Card.Footer>
        </Card>
      </Form>
      <ToastContainer style={{ top: "60px" }} />
      <Modals showModal={showPopup} handleClose={handlePopupClose} />
    </div>
  );
}
