import { FETCH_USERS_SUCCESS } from "./UsersActionTypes";

const initialState = { users: [] };

export const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_SUCCESS:
      return { ...state, users: action.data };

    default:
      return state;
  }
};
