import {
  CREATE_SESSION,
  DESTROY_SESSION,
  BANNER_INFO,
  UPDATE_PREFERENCES,
  UPDATE_PROFILE,
} from "./AuthActionTypes";

export const onLogout = () => (dispatch) => {
  dispatch({ type: DESTROY_SESSION });
};

export const onLogIn = (user) => (dispatch) => {
  dispatch({ type: CREATE_SESSION, user });
};
export const handleAccountPreferences = (user) => (dispatch) => {
  dispatch({ type: UPDATE_PREFERENCES, user });
};
export const handleUpdateProfile = (user) => (dispatch) => {
  dispatch({ type: UPDATE_PROFILE, user });
};
export const handleBanner = (user) => (dispatch) => {
  dispatch({ type: BANNER_INFO, user });
};
