import React from "react";
import { Col, Row } from "react-bootstrap";

function Head(props) {
  const { title, children } = props;
  return (
    <Row className="pb-4 gx-0">
      <Col>
        <h3 className="m-0">{title}</h3>
      </Col>
      <Col xs="auto" className="d-flex">
        {children}
      </Col>
    </Row>
  );
}

export default Head;
