import React from "react";
import { useState } from "react";
import {
  Container,
  Button,
  Card,
  Row,
  Col,
  InputGroup,
  Badge,
} from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import UpdateProfile from "./UpdateProfile";
import * as yup from "yup";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Modals from "../components/Modal";
import { Formik } from "formik";
function Profile1() {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.userInfo);
  const user = Object.keys(userInfo).length && userInfo?.user_data;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [checkVal, setCheckVal] = useState();
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [cnfPasswordShown, setCnfPasswordShown] = useState(false);
  const [oldpass, setOldpass] = useState("");
  const [newpass, setnewpass] = useState("");
  const [showPopup, setShowPopup] = useState({ is: false, msg: "" });
  const [confirmpass, setconfirmpass] = useState("");
  const handleShow = () => {
    setShow(true);
    setOldpass("");
    setconfirmpass("");
    setnewpass("");
  };
  const schema = yup.object().shape({
    old_pass: yup
      .string()
      .required("Field is required")
      .max(12, "you can enter only 12 characters")
      .min(5, "Enter Atleast 5 characters"),
    new_pass: yup
      .string()
      .required("Field is required")
      .max(12, "you can enter only 12 characters")
      .min(5, "Enter Atleast 5 characters")
      .notOneOf(
        [yup.ref("old_pass")],
        "New password must not be the same as old password"
      ),
    cnf_pass: yup
      .string()
      .required("Field is required")
      .oneOf([yup.ref("new_pass"), null], "Password did not matched"),
  });

  const handlePopupClose = () => {
    setShowPopup({
      ...showPopup,
      is: false,
      msg: "",
    });
  };

  const Submit = async (formValues) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_UPDATE_PROFILE_PASSWORD}`,
        {
          user_id: user,
          old_pass: formValues.old_pass,
          new_pass: formValues.new_pass,
        }
      );

      if (result.status === 200) {
        toast.success("Password Changed successfully", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          navigate("/profile");
        }, 4000);
      } else {
        setShowPopup({
          ...showPopup,
          is: true,
          msg: "Password cannot be changed",
        });
        console.error("Error:");
      }
    } catch (error) {
      setShowPopup({
        ...showPopup,
        is: true,
        msg: "",
      });
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Container>
        <Form.Text
          className=" d-flex justify-content-center mt-3 text-dark"
          style={{ fontWeight: "600", fontSize: "25px" }}
        >
          Profile
        </Form.Text>
        <div className="d-flex justify-content-center ">
          <Card
            className="  border-0 bg-light"
            style={{
              width: "800px",
              minHeight: "400px",
              marginTop: "35px",
              boxShadow:
                "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px",
            }}
          >
            <Row>
              <Col
                sm={5}
                className=""
                style={{ borderRight: "1px solid rgb(207 207 207 / 39%)" }}
              >
                {" "}
                <div className="d-flex justify-content-center mt-4">
                  <img
                    src="/images/profileimg.png"
                    alt="logo"
                    style={{
                      width: "120px",
                      height: "120px",
                      marginBottom: "20px",
                    }}
                    rounded
                  />
                </div>
                <div>
                  <Form.Text className="text11 text-center  d-block ">
                    {`${user.firstname} ${user.lastname}`}{" "}
                    <Badge bg="success">
                      {user.role.role.charAt(0).toUpperCase() +
                        user.role.role.slice(1)}
                    </Badge>
                  </Form.Text>
                  {/* <Form.Text className="text11 text-center d-lock my-2"></Form.Text> */}
                  <Form.Text className="text11 text-center  d-block ">
                    {user.email}
                  </Form.Text>
                </div>
                <div className="text-center mt-4">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Click to change password
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="primary"
                      className="btn-sm"
                      onClick={handleShow}
                    >
                      Change Password
                    </Button>
                  </OverlayTrigger>
                </div>
              </Col>
              <Col sm={7}>
                <UpdateProfile />
              </Col>
            </Row>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex justify-content-center ">
                  <Card
                    className=" p-0  border-0 bg-light"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Formik
                      validationSchema={schema}
                      noValidate
                      onSubmit={(values, { setSubmitting }) => {
                        Submit(values);
                        setSubmitting(false);
                      }}
                      initialValues={{
                        old_pass: "",
                        new_pass: "",
                        cnf_pass: "",
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label
                              style={{
                                fontSize: "16px",
                                fontWeight: "700",
                              }}
                            >
                              Old Password
                            </Form.Label>

                            <InputGroup className="rtrtrr">
                              <Form.Control
                                style={{ height: "46px" }}
                                name="old_pass"
                                type={oldPasswordShown ? "text" : "password"}
                                placeholder="Old password"
                                className="txtfield"
                                value={values.old_pass}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  !!touched.old_pass && !!errors.old_pass
                                }
                              />
                              <InputGroup.Text
                                className="login-hide"
                                onClick={() =>
                                  setOldPasswordShown(!oldPasswordShown)
                                }
                              >
                                {oldPasswordShown ? (
                                  <AiOutlineEye />
                                ) : (
                                  <AiOutlineEyeInvisible />
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            {errors?.old_pass && (
                              <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                              >
                                {errors?.old_pass}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label
                              style={{
                                fontSize: "16px",
                                fontWeight: "700",
                              }}
                            >
                              New Password
                            </Form.Label>
                            <InputGroup className="rtrtrr">
                              <Form.Control
                                style={{ height: "46px" }}
                                name="new_pass"
                                type={newPasswordShown ? "text" : "password"}
                                placeholder="New password"
                                className="txtfield"
                                value={values.new_pass}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  !!touched.new_pass && !!errors.new_pass
                                }
                              />
                              <InputGroup.Text
                                className="login-hide"
                                onClick={() =>
                                  setNewPasswordShown(!newPasswordShown)
                                }
                              >
                                {newPasswordShown ? (
                                  <AiOutlineEye />
                                ) : (
                                  <AiOutlineEyeInvisible />
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            {errors?.new_pass && (
                              <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                              >
                                {errors.new_pass}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label
                              style={{
                                fontSize: "16px",
                                fontWeight: "700",
                              }}
                            >
                              Confirm Password
                            </Form.Label>
                            <InputGroup className=" rtrtrr">
                              <Form.Control
                                style={{ height: "46px" }}
                                name="cnf_pass"
                                type={cnfPasswordShown ? "text" : "password"}
                                placeholder="Confirm password"
                                className="txtfield"
                                value={values.cnf_pass}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  !!touched.cnf_pass && !!errors.cnf_pass
                                }
                              />
                              <InputGroup.Text
                                className="login-hide"
                                onClick={() =>
                                  setCnfPasswordShown(!cnfPasswordShown)
                                }
                              >
                                {cnfPasswordShown ? (
                                  <AiOutlineEye />
                                ) : (
                                  <AiOutlineEyeInvisible />
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            {errors?.cnf_pass && (
                              <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                              >
                                {errors.cnf_pass}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>

                          <div className="d-flex justify-content-end mt-5 ">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="button-tooltip">
                                  Click to back profile
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="secondary"
                                className="btn-sm me-3"
                                onClick={handleClose}
                              >
                                Close
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="button-tooltip">
                                  Click to Submit
                                </Tooltip>
                              }
                            >
                              <Button
                                type="submit"
                                disabled={checkVal}
                                variant="primary"
                                className="btn-sm"
                              >
                                Submit
                              </Button>
                            </OverlayTrigger>
                          </div>

                          <ToastContainer />
                          <Modals
                            showModal={showPopup}
                            handleClose={handlePopupClose}
                          />
                        </Form>
                      )}
                    </Formik>
                  </Card>
                </div>
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default Profile1;
