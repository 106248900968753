import {
  CREATE_SESSION,
  DESTROY_SESSION,
  UPDATE_PREFERENCES,
  BANNER_INFO,
  UPDATE_PROFILE,
} from "./AuthActionTypes";

const initialState = {
  isLoading: false,
  userInfo: {},
  isLoggedIn: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case DESTROY_SESSION:
      return { ...state, isLoggedIn: false };
    case CREATE_SESSION: {
      const data = { ...state.user, ...action.user };
      return { ...state, isLoading: true, isLoggedIn: true, userInfo: data };
    }
    case UPDATE_PREFERENCES: {
      const data = { ...state.user, ...action.user };
      return { ...state, isLoading: true, isLoggedIn: true, userInfo: data };
    }
    case UPDATE_PROFILE: {
      const data = { ...state.user, ...action.user };
      return { ...state, isLoading: true, isLoggedIn: true, userInfo: data };
    }
    case BANNER_INFO: {
      const data = { ...state.user, ...action.user };
      const tempData = { ...data, showBanner: false };
      return {
        ...state,
        isLoading: true,
        isLoggedIn: true,
        userInfo: tempData,
      };
    }
    default:
      return state;
  }
};
