import React, { useRef, useEffect } from "react";
import { Card, Form, Row, Table, Col, Button } from "react-bootstrap";
import { BsPercent } from "react-icons/bs";
import generatePDF from "react-to-pdf";
import "./Template1.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { ToastContainer } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import AutoComplete from "../AutoComplete/AutoComplete";

export default function Templates4(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const ref = React.createRef();
  const { userInfo } = useSelector((state) => state.userInfo);
  const formatDate = userInfo?.account_preferences?.date_formate;
  const {
    requestType,
    isCreateInvoice,
    applyGST,
    applyDIS,
    toWords,
    addFields,
    removeFields,
    setApplyGST,
    setApplyDIS,
    disableSubmit,
    formik,
    showTemplate,
    handlePaidToChange,
  } = props;
  let subTotal = 0;
  let total = 0;
  let Quantity = 0;
  let discountVal = 0;
  let appliedgst = 0;
  let applieddiscount = 0;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const componentRef = useRef();
  function numberonly(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9 || .]/.test(ch)) {
      evt.preventDefault();
    }
  }

  function numberonly1(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9]/.test(ch)) {
      evt.preventDefault();
    }
  }
  useEffect(() => {
    if (state?.download === "true") {
      document.getElementById("pdf-button").click();
      navigate("/invoices");
    }
  }, [state?.download]);
  const {
    touched,
    errors,
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (parseFloat(values.has_discounts) > 0) {
      setApplyDIS(true);
    } else {
      setApplyDIS(false);
    }
    if (parseFloat(values.has_gst) > 0) {
      setApplyGST(true);
    } else {
      setApplyGST(false);
    }
  }, [isCreateInvoice]);

  useEffect(() => {
    if (parseFloat(discountVal) !== parseFloat(values?.total_amount)) {
      setFieldValue("total_amount", discountVal);
    }
  }, [discountVal, applyGST, applyDIS, values]);

  return (
    <div className="main222">
      <Card style={{ width: "800px" }}>
        <Form noValidate onSubmit={handleSubmit}>
          <div ref={componentRef}>
            <div ref={ref} className="px-4 pt-3">
              <Row>
                <Col>
                  <Form.Text className="text11 text-start">
                    {userInfo?.account_preferences?.address
                      ? `${userInfo?.account_preferences?.address}`
                      : "{Company address}"}
                  </Form.Text>
                  <Form.Text className="text11 text-start">
                    {userInfo?.account_preferences?.city ||
                    userInfo?.account_preferences?.state
                      ? `${userInfo?.account_preferences?.city}, ${userInfo?.account_preferences?.state}`
                      : "{City, State}"}
                  </Form.Text>
                  <Form.Text className="text11 text-start">
                    {" "}
                    {userInfo?.account_preferences?.pincode
                      ? userInfo?.account_preferences?.pincode
                      : "{Pincode}"}
                  </Form.Text>
                  <Form.Text className="text11 text-start">
                    {userInfo?.account_preferences?.contact_no
                      ? `${userInfo?.account_preferences?.contact_no}`
                      : "{Contact number}"}
                    {userInfo?.account_preferences?.alt_contact_no
                      ? `, ${userInfo?.account_preferences?.alt_contact_no}`
                      : ""}
                  </Form.Text>
                  <Form.Text className="text11 text-start">
                    {userInfo?.account_preferences?.email_address
                      ? userInfo?.account_preferences?.email_address
                      : "{Email address}"}
                  </Form.Text>
                </Col>
                {!isCreateInvoice && (
                  <Col>
                    <Form.Text className="temp3text1 text-end ">
                      Invoice
                    </Form.Text>
                    <Form.Text
                      className="text11 p-0"
                      style={{ lineHeight: "1.2", textAlign: "right" }}
                    >
                      {values?.invoice_no}
                    </Form.Text>
                    {/* <Form.Control
                      type="text"
                      className="text11 shadow-none pe-0"
                      placeholder="Enter Invoice No."
                      required
                      value={values?.invoice_no}
                      readOnly
                    /> */}
                  </Col>
                )}
              </Row>

              <Row>
                <Col className="mt-4">
                  <div>
                    <Form.Text className="text111 text-start d-block mb-1">
                      BILL TO:
                    </Form.Text>
                    {requestType === "edit" || isCreateInvoice ? (
                      <AutoComplete
                        requestType="bills"
                        searchingFor="paid_to"
                        callbackFieldValue={handlePaidToChange}
                        fieldValue={values.paid_to}
                        className="mb-1 w-50"
                        name="paid_to"
                        id="paid_to"
                        placeholder="Enter Company Name"
                        readOnly={!(requestType === "edit" || isCreateInvoice)}
                      />
                    ) : (
                      <Form.Text
                        className="text11   text-start"
                        style={{ width: "50%" }}
                      >
                        {values?.paid_to}
                      </Form.Text>
                    )}
                    {touched.paid_to && errors.paid_to && (
                      <Form.Control.Feedback
                        className="d-block mb-2 "
                        type="invalid"
                      >
                        {errors.paid_to}
                      </Form.Control.Feedback>
                    )}
                    <Form.Text className="text111 text-start d-block mb-1">
                      ADDRESS:
                    </Form.Text>
                    {requestType === "edit" || isCreateInvoice ? (
                      <AutoComplete
                        requestType="bills"
                        searchingFor="address"
                        name="address"
                        callbackFieldValue={(e) =>
                          setFieldValue("address", e.target.value)
                        }
                        fieldValue={values.address}
                        as="textarea"
                        rows={1}
                        required
                        className="mb-1 w-50"
                        placeholder="Enter Address"
                        readOnly={!(requestType === "edit" || isCreateInvoice)}
                      />
                    ) : (
                      <Form.Text
                        className="text11  text-start "
                        style={{ width: "50%" }}
                      >
                        {values?.address}
                      </Form.Text>
                    )}
                    {touched.address && errors.address && (
                      <Form.Control.Feedback
                        className="d-block mb-2 "
                        type="invalid"
                      >
                        {errors.address}
                      </Form.Control.Feedback>
                    )}
                    <Form.Text className="text111 text-start mb-1">
                      DATE:
                    </Form.Text>
                    {requestType === "edit" || isCreateInvoice ? (
                      <div>
                        <DatePicker
                          wrapperClassName="datewrapper-temp-3"
                          customInput={
                            <Form.Control aria-describedby="basic-addon1" />
                          }
                          required
                          name="date"
                          id="date"
                          format={
                            formatDate === null || formatDate === undefined
                              ? "dd MMM, yyyy"
                              : formatDate
                                  ?.replace(/D/g, "d")
                                  ?.replace(/Y/g, "y")
                          }
                          maxDate={new Date()}
                          selected={new Date(values?.date)}
                          onChange={(e) =>
                            setFieldValue(
                              "date",
                              moment(e).format("YYYY-MM-DD")
                            )
                          }
                          readOnly={
                            !(requestType === "edit" || isCreateInvoice)
                          }
                        />

                        <Form.Control.Feedback
                          className="d-block mb-1"
                          type="invalid"
                        >
                          {errors.date}
                        </Form.Control.Feedback>
                      </div>
                    ) : (
                      <Form.Text
                        className="text11  mx-2 "
                        style={{ textAlign: "left" }}
                      >
                        {moment(values?.date).format(
                          `${
                            formatDate === null || formatDate === undefined
                              ? "dd MMM, yyyy"
                              : formatDate
                          }`
                        )}
                      </Form.Text>
                    )}
                  </div>
                </Col>
              </Row>

              <div className="mx-3 mt-3">
                <Table striped hover>
                  <thead>
                    <tr>
                      <th
                        className="text9 text-start"
                        style={{
                          backgroundColor: "#e8a43e",
                        }}
                      >
                        Details
                      </th>
                      <th
                        className="text9 text-start"
                        style={{ width: "120px", backgroundColor: "#e8a43e" }}
                      >
                        Item Price
                      </th>
                      <th
                        className="text9 text-start"
                        style={{ width: "120px", backgroundColor: "#e8a43e" }}
                      >
                        Quantity
                      </th>
                      <th
                        className="text9 text-start"
                        style={{
                          width: "140px",
                          backgroundColor: "#e8a43e",
                        }}
                      >
                        Payments
                      </th>

                      {(requestType === "edit" ||
                        (isCreateInvoice &&
                          values?.bill_description.length > 1)) && (
                        <th
                          className="text9 text-end"
                          style={{
                            backgroundColor: "#e8a43e",
                          }}
                        ></th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {values.bill_description.map((row, index) => {
                      total +=
                        row.quantity || row.item_price
                          ? parseFloat(row.quantity * row.item_price)
                          : 0;
                      subTotal = total;
                      Quantity += row.quantity ? parseFloat(row.quantity) : 0;

                      appliedgst = (subTotal * (values?.has_gst || 0)) / 100;
                      applieddiscount =
                        (subTotal * (values?.has_discounts || 0)) / 100;

                      discountVal = (
                        parseFloat(subTotal) +
                        parseFloat(applyGST ? appliedgst : 0) -
                        parseFloat(applyDIS ? applieddiscount : 0)
                      ).toFixed(2);
                      return (
                        <tr key={index}>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <AutoComplete
                                requestType="billdescriptions"
                                searchingFor="item_description"
                                name={`bill_description.${index}.item_description`}
                                callbackFieldValue={(e) =>
                                  setFieldValue(
                                    `bill_description.${index}.item_description`,
                                    e.target.value
                                  )
                                }
                                fieldValue={row.item_description}
                                as="textarea"
                                rows={1}
                                required
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                              />
                            ) : (
                              <Form.Text
                                className="text11  text-start text-dark"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `bill_description.${index}.item_description`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `bill_description.${index}.item_description`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.bill_description &&
                              touched.bill_description[index]
                                ?.item_description &&
                              errors.bill_description &&
                              errors.bill_description[index]
                                ?.item_description && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.bill_description &&
                                    errors.bill_description[index]
                                      ?.item_description}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <Form.Control
                                maxLength={7}
                                required
                                rows={1}
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                                onKeyPress={numberonly}
                                name="item_price"
                                {...getFieldProps(
                                  `bill_description.${index}.item_price`
                                )}
                                type="text"
                              />
                            ) : (
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `bill_description.${index}.item_price`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `bill_description.${index}.item_price`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.bill_description &&
                              touched.bill_description[index]?.item_price &&
                              errors.bill_description &&
                              errors.bill_description[index]?.item_price && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.bill_description &&
                                    errors.bill_description[index]?.item_price}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <Form.Control
                                maxLength={4}
                                required
                                rows={1}
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                                onKeyPress={numberonly1}
                                name="quantity"
                                {...getFieldProps(
                                  `bill_description.${index}.quantity`
                                )}
                                type="text"
                              />
                            ) : (
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `bill_description.${index}.quantity`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `bill_description.${index}.quantity`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.bill_description &&
                              touched.bill_description[index]?.quantity &&
                              errors.bill_description &&
                              errors.bill_description[index]?.quantity && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.bill_description &&
                                    errors.bill_description[index]?.quantity}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <Form.Control
                                onKeyPress={numberonly}
                                value={(row.item_price * row.quantity).toFixed(
                                  2
                                )}
                                rows={1}
                                readOnly
                                name="Payments"
                                type="text"
                              />
                            ) : (
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                              >
                                {(row.item_price * row.quantity).toFixed(2)}
                              </Form.Text>
                            )}
                          </td>
                          {(requestType === "edit" ||
                            (isCreateInvoice &&
                              values?.bill_description.length > 1)) && (
                            <td className="text-center fs-5 pt-3">
                              <AiOutlineClose
                                className="text-danger mt-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => removeFields(index, row?.id)}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Row className="mb-2">
                  <Col sm={6} className="ps-0">
                    <Form.Text className="text111 text-start p-0">
                      Mode of Payment:
                    </Form.Text>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to select the payment mode
                        </Tooltip>
                      }
                    >
                      {requestType === "edit" || isCreateInvoice ? (
                        <Form.Select
                          className=" mt-1"
                          style={{
                            maxWidth: "240px",
                            // fontWeight: "400",
                            fontSize: "15px",
                          }}
                          required
                          name="payment_mode"
                          value={values?.payment_mode}
                          onChange={(e) => {
                            setFieldValue("payment_mode", e.target.value);
                          }}
                          disabled={
                            !(requestType === "edit" || isCreateInvoice)
                          }
                        >
                          <option value="">Select Payment Mode</option>
                          <option value="Bank">Bank</option>
                          <option value="Cash">Cash</option>
                          <option value="Card">Card</option>
                          <option value="Cheque">Cheque</option>
                          <option value="UPI">UPI</option>
                        </Form.Select>
                      ) : (
                        <Form.Text
                          className="text4  text-start"
                          style={{ width: "100%" }}
                        >
                          {values?.payment_mode}
                        </Form.Text>
                      )}
                    </OverlayTrigger>
                    {touched.payment_mode && errors.payment_mode && (
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {errors.payment_mode}
                      </Form.Control.Feedback>
                    )}
                    {values?.payment_mode !== "Cash" &&
                      values?.payment_mode !== "" && (
                        <Col className="pt-1">
                          <Form.Text className="text111 text-start p-0 mb-1 ">
                            {values?.payment_mode === "Bank"
                              ? "Transaction Id:"
                              : values?.payment_mode === "Cheque"
                              ? "Cheque Number:"
                              : values?.payment_mode === "Card"
                              ? "Enter last 4 digits of your card:"
                              : values?.payment_mode === "UPI"
                              ? "UPI Reference Id:"
                              : ""}
                          </Form.Text>
                          {requestType === "edit" || isCreateInvoice ? (
                            <Form.Control
                              style={{ maxWidth: "240px" }}
                              maxLength={25}
                              required
                              name="payment_mode_info"
                              readOnly={
                                !(requestType === "edit" || isCreateInvoice)
                              }
                              value={values.payment_mode_info}
                              onChange={(e) => {
                                setFieldValue(
                                  "payment_mode_info",
                                  e.target.value
                                );
                              }}
                              placeholder={
                                values?.payment_mode === "Bank"
                                  ? "Enter the Transaction Id:-"
                                  : values?.payment_mode === "Cash"
                                  ? "Enter Amount:-"
                                  : values?.payment_mode === "Cheque"
                                  ? "Enter Cheque Number:-"
                                  : values?.payment_mode === "Card"
                                  ? "Enter last 4 digits of your card:-"
                                  : values?.payment_mode === "UPI"
                                  ? "Enter UPI Reference Id:-"
                                  : ""
                              }
                            />
                          ) : (
                            <Form.Text
                              className="text4  text-start"
                              style={{ width: "100%" }}
                            >
                              {values.payment_mode_info}
                            </Form.Text>
                          )}
                          {touched.payment_mode_info &&
                            errors.payment_mode_info && (
                              <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                              >
                                {errors.payment_mode_info}
                              </Form.Control.Feedback>
                            )}
                        </Col>
                      )}
                  </Col>
                  <Col sm={6} className="px-0">
                    <Row className="d-flex align-items-center">
                      <Col xs={5} sm={5} className="px-0">
                        <Form.Text className="text111 text-start">
                          Sub Total
                        </Form.Text>
                      </Col>

                      <Col xs={7} sm={7} className="text-end">
                        <Form.Text className="sizewt">
                          {`₹${total.toFixed(2)}`}
                        </Form.Text>
                      </Col>
                    </Row>

                    <Row className="my-1 d-flex align-items-center">
                      <Col sm={5} xs={5} className="px-0">
                        <Form.Text className="text111 text-start">
                          Amount in Words:
                        </Form.Text>
                      </Col>
                      <Col sm={7} xs={7} className="text-end ">
                        <Form.Text className=" sizewt  ">
                          {toWords.convert(parseInt(Math.round(total)))}
                        </Form.Text>
                      </Col>
                    </Row>
                    {!(
                      requestType === "view" &&
                      !isCreateInvoice &&
                      !values?.has_gst
                    ) && (
                      <Row className="m-0">
                        <Col sm={5} xs={5} className="p-0">
                          <Form.Group controlId="ApplyGST" md="auto">
                            <Form.Check
                              className="sizewt text-start"
                              checked={applyGST}
                              onChange={() => setApplyGST(!applyGST)}
                              type="checkbox"
                              label="Apply GST"
                              disabled={
                                !(requestType === "edit" || isCreateInvoice)
                              }
                            />
                          </Form.Group>
                        </Col>
                        {applyGST && (
                          <>
                            <Col
                              sm={3}
                              xs={3}
                              className=" d-flex flex-row  pe-0 text-start px-0"
                            >
                              <div>
                                <Form.Control
                                  className="gstdis shadow-none sizewt text-end"
                                  name="has_gst"
                                  maxLength={5}
                                  type="number"
                                  value={values?.has_gst}
                                  readOnly={
                                    !(requestType === "edit" || isCreateInvoice)
                                  }
                                  onChange={(e) => {
                                    setFieldValue("has_gst", e.target.value);
                                  }}
                                />
                              </div>
                              <div className="d-flex flex-row sizewt">
                                {" "}
                                <BsPercent className="mt-1" />
                                &nbsp;=&nbsp;
                              </div>
                            </Col>
                            <Col sm={4} xs={4} className="text-end ">
                              <Form.Text className="sizewt">
                                {`₹${appliedgst.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </>
                        )}
                      </Row>
                    )}
                    {!(
                      requestType === "view" &&
                      !isCreateInvoice &&
                      !values?.has_discounts
                    ) && (
                      <Row className="m-0">
                        <Col sm={5} xs={5} className="p-0">
                          <Form.Group controlId="applyDIS" md="auto">
                            <Form.Check
                              className="sizewt text-start"
                              disabled={
                                !(requestType === "edit" || isCreateInvoice)
                              }
                              checked={applyDIS}
                              onChange={() => setApplyDIS(!applyDIS)}
                              type="checkbox"
                              label="Apply Discount"
                            />
                          </Form.Group>
                        </Col>
                        {applyDIS && (
                          <>
                            <Col
                              sm={3}
                              xs={3}
                              className=" d-flex flex-row  pe-0 text-start px-0"
                            >
                              <div>
                                <Form.Control
                                  className="gstdis shadow-none sizewt text-end"
                                  maxLength={3}
                                  type="number"
                                  readOnly={
                                    !(requestType === "edit" || isCreateInvoice)
                                  }
                                  value={values?.has_discounts}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "has_discounts",
                                      e.target.value
                                    );
                                  }}
                                  name="disctval"
                                />
                              </div>
                              <div className="d-flex flex-row sizewt">
                                {" "}
                                <BsPercent className="mt-1" />
                                &nbsp;=&nbsp;
                              </div>
                            </Col>
                            <Col sm={4} xs={4} className="text-end ">
                              <Form.Text className="sizewt">
                                {`₹${applieddiscount.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </>
                        )}
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col className="d-flex align-items-center ps-0" sm={6}>
                    {!(
                      requestType === "view" &&
                      !isCreateInvoice &&
                      !values?.note
                    ) && (
                      <div style={{ width: "100%" }}>
                        <Form.Text className="text111 text-start mt-0 mb-1">
                          Notes:
                        </Form.Text>
                        {requestType === "edit" || isCreateInvoice ? (
                          <AutoComplete
                            requestType="bills"
                            searchingFor="note"
                            callbackFieldValue={(e) =>
                              setFieldValue("note", e.target.value)
                            }
                            fieldValue={values.note}
                            className="my-1 w-100"
                            name="note"
                            id="note"
                            placeholder="Write something here"
                            readOnly={
                              !(requestType === "edit" || isCreateInvoice)
                            }
                          />
                        ) : (
                          <Form.Text
                            className="text4 border-0   text-start"
                            style={{ width: "100%" }}
                          >
                            {values && values?.note}
                          </Form.Text>
                        )}
                      </div>
                    )}
                  </Col>

                  <Col
                    className="d-flex justify-content-between d-flex align-items-center"
                    sm={6}
                    style={{ backgroundColor: "#e8a43e" }}
                  >
                    <Form.Text className="total3 ">Total</Form.Text>
                    <Form.Text className="total3 ">
                      {`₹${parseFloat(discountVal).toFixed(2)}`}
                    </Form.Text>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          {!showTemplate && (
            <Row className=" position-sticky bottom-0 p-4 m-0 ">
              {requestType === "edit" || isCreateInvoice ? (
                <>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to change the template
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        style={{ width: "100%" }}
                        onClick={() =>
                          navigate("/invoiceTemplate", {
                            state: {
                              values: formik?.values,
                              requestType: requestType || isCreateInvoice,
                            },
                          })
                        }
                      >
                        Change Template
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to add one row below
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        style={{ width: "100%" }}
                        onClick={addFields}
                      >
                        Add Row
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to submit the data to all invoices
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="primary"
                        style={{ width: "100%" }}
                        type="submit"
                        disabled={disableSubmit}
                      >
                        {disableSubmit ? (
                          <div className="d-flex justify-content-center">
                            <div
                              className="spinner-border"
                              role="status"
                              style={{ height: "21px", width: "21px" }}
                            ></div>
                          </div>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </OverlayTrigger>
                    <ToastContainer style={{ top: "60px" }} />
                  </Col>
                </>
              ) : (
                <>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to download the data in PDF
                        </Tooltip>
                      }
                    >
                      <Button
                        id="pdf-button"
                        onClick={() => {
                          generatePDF(componentRef, {
                            filename: `${values?.invoice_no}.pdf`,
                          });
                        }}
                        variant="primary"
                        style={{
                          width: "100%",
                          marginRight: "3px",
                        }}
                      >
                        Download
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to print the data in PDF
                        </Tooltip>
                      }
                    >
                      <Button
                        className="primary w-100"
                        variant="secondary"
                        style={{
                          width: "100%",
                          marginLeft: "3px",
                        }}
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </>
              )}
            </Row>
          )}
        </Form>
      </Card>
    </div>
  );
}
