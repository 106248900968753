// Modal.js
import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

const CustomExport = ({ isOpen, onClose }) => {
  return (
    <Modal show={isOpen} centered>
      <Modal.Header className="border-0 justify-content-center fw-bold fs-6">
        Select Export Format for All Data
      </Modal.Header>
      <Modal.Body className="text-center py-3">
        <Row className="px-5 m-3">
          <Col>
            {" "}
            <Button size="sm" variant="primary" className="w-100 py-3 fs-6">
              Export to Excel
            </Button>
          </Col>
        </Row>

        <Row className="px-5 m-3">
          <Col>
            {" "}
            <Button size="sm" variant="primary" className="w-100 py-3 fs-6">
              Export to CSV
            </Button>
          </Col>
        </Row>
        <Row className="px-5 m-3">
          <Col>
            {" "}
            <Button size="sm" variant="primary" className="w-100 py-3 fs-6">
              Export to PDF
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button size="sm" variant="light" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomExport;
