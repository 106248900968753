/* eslint-disable no-nested-ternary */
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Row, Container } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { handleBanner } from "../store/Auth/AuthActions";

export default function InformativeBanner() {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state?.userInfo);
  const { showBanner } = userInfo;
  const dispatch = useDispatch();

  function banner() {
    if (
      !(
        userInfo?.account_preferences &&
        Object.keys(userInfo?.account_preferences)?.length > 0 &&
        userInfo?.account_preferences?.address !== null &&
        userInfo?.account_preferences?.address !== undefined &&
        userInfo?.account_preferences?.attached_logo !== null &&
        userInfo?.account_preferences?.attached_logo !== undefined
      )
    ) {
      return (
        <div>
          <Row className="m-1 align-items-center">
            <Col className="col-12 col-md-8">
              <h6>Setting Up Your Account Preferences</h6>
              <p style={{ color: "#666276", fontWeight: 500 }}>
                Start by Setting Up Your Account Preferences Before Starting
                Work Here!
              </p>
              <div style={{ marginTop: "15px" }}>
                {window.location.pathname === "/account_preferences" ? null : (
                  <Button
                    onClick={() => navigate("/account_preferences")}
                    variant="success"
                    size="sm"
                  >
                    Set Account Preferences
                  </Button>
                )}
              </div>
            </Col>
            <Col className="col-md-4 d-none d-md-block">
              <div className="d-flex justify-content-center">
                <img
                  src="/images/accountCharge.png" // Replace with your image URL
                  alt="accontPrefrences"
                  style={{
                    width: "55%", // Adjust the width as needed
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    return "";
  }
  const hideBanner = () => {
    dispatch(handleBanner(userInfo));
  };
  if (!showBanner && banner() === "") return <></>;

  return userInfo !== undefined && showBanner && banner() ? (
    <Container>
      <div style={{ flexGrow: 1, position: "relative" }} className="mb-3">
        <div
          style={{
            width: "100%",
            fontWeight: "700",
            position: "relative",
            borderRadius: 8,
            color: "#fd5631ed",
            backgroundColor: "rgba(241, 124, 58, 0.14)",
          }}
          className="py-1 px-3"
        >
          <Button
            variant="outline-danger"
            size="sm"
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              minWidth: "auto",
            }}
            onClick={() => hideBanner()}
          >
            <AiOutlineClose />
          </Button>

          {banner()}
        </div>
      </div>
    </Container>
  ) : (
    <div />
  );
}
