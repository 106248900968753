import "../App.css";
import { Button, Modal } from "react-bootstrap";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Head from "../components/NavBar/Head";
import { BsDownload, BsEye, BsPen, BsTrash3 } from "react-icons/bs";
import { renderToStaticMarkup } from "react-dom/server";
import DataTables from "../components/DataTables";
import axios from "axios";
import Modals from "../components/Modal";
import { IoDuplicateOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { SET_REFRESH_DATA_TABLE } from "../store/Theme/ThemeActionTypes";
import moment from "moment";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { paymentModes } from "../Utils/Constants";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  DateRangePicker,
  createStaticRanges,
  defaultStaticRanges,
} from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ToastContainer, toast } from "react-toastify";
import { endOfYear, startOfYear, subYears } from "date-fns";
import MultiRange from "../components/CustomRangeSlider/MultiRange";

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userInfo);
  const checkRole = userInfo?.user_data?.role;
  const [selectedItems, setSelectedItems] = useState([]);
  const [additionalFilter, setAdditionalFilter] = useState({});
  const [showPopup, setShowPopup] = useState({ is: false, msg: "" });
  const formatDate = userInfo?.account_preferences?.date_formate;
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const checkAll = document.querySelector(".checkAll");
  const checkedCheckBoxes = document.querySelectorAll(".checkBox:checked");

  const handlePopupClose = () => {
    setShowPopup({
      ...showPopup,
      is: false,
      msg: "",
    });
  };
  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    setAdditionalFilter({
      ...additionalFilter,
      dateRange: `${moment(ranges.selection.startDate).format(
        "YYYY-MM-DD"
      )} | ${moment(ranges.selection.endDate).format("YYYY-MM-DD")}`,
    });
  };
  const [deleteInvoiceAction, setDeleteInvoiceAction] = useState({
    showConfirmationModal: false,
  });

  const columns = [
    {
      data: "id",
      class: "d-none",
    },
    {
      data: "invoice_no",
      class: "d-none",
    },
    {
      data: null,
      title:
        '<input class="form-check-input checkAll" type="checkbox" data-action="checkAll">',
      width: "20px",
      class: "text-center align-middle",
      orderable: false,
      visible:
        checkRole?.invoice?.includes("delete") ||
        checkRole?.invoice?.includes("Delete"),
      render: function (data, type, row) {
        return (
          `<input type="checkbox" class="form-check-input checkBox" name="posts[]" value="` +
          row["id"] +
          `" data-action="checkBox">`
        );
      },
    },
    {
      data: "paid_to",
      title: "Bill To",
      class: "text-left align-middle",
    },
    {
      data: "date",
      class: "text-left align-middle",
      title: "Invoice Date",
      render: function (data, type, row) {
        return moment(row.date).format(
          `${
            formatDate === null || formatDate === undefined
              ? "DD MMM, YYYY"
              : formatDate
          }`
        );
      },
    },
    {
      data: "payment_mode",
      class: "text-left align-middle",
      title: "Payment Mode",
    },
    {
      data: "total_amount",
      class: "text-left align-middle",

      title: "Amount",
      render: function (data, type, row) {
        return `₹${row?.total_amount}`;
      },
    },
    {
      data: null,
      class: "text-left  align-middle",
      title: "Actions",
      width: "250px",
      orderable: false,
      render: function (data, type, row) {
        return renderToStaticMarkup(
          <div className="text-right">
            {(checkRole?.invoice?.includes("view") ||
              checkRole?.invoice?.includes("View")) && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="button-tooltip">View the invoice</Tooltip>
                }
              >
                <Button
                  variant="light"
                  size="sm"
                  data-id={row.id}
                  data-action="view"
                  className="btn-icon mx-1"
                  onClick="handleButtonClick('sdf')"
                >
                  <BsEye size={15} />
                </Button>
              </OverlayTrigger>
            )}
            {(checkRole?.invoice?.includes("edit") ||
              checkRole?.invoice?.includes("Edit")) && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip">Edit</Tooltip>}
              >
                <Button
                  variant="light"
                  size="sm"
                  className="btn-icon mx-1"
                  data-id={row.id}
                  data-action="edit"
                >
                  <BsPen size={15} />
                </Button>
              </OverlayTrigger>
            )}
            {(checkRole?.invoice?.includes("create") ||
              checkRole?.invoice?.includes("Create")) && (
              <Button
                variant="light"
                size="sm"
                data-id={row.id}
                data-action="handleDuplicate"
                className="btn-icon mx-1"
              >
                <IoDuplicateOutline size={15} />
              </Button>
            )}
            {(checkRole?.invoice?.includes("view") ||
              checkRole?.invoice?.includes("View")) && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip">Download invoice</Tooltip>
                }
              >
                <Button
                  variant="light"
                  size="sm"
                  className="me-2 btn-icon mx-1"
                  data-id={row.id}
                  data-action="downloadAsPdf"
                >
                  <BsDownload size={15} />
                </Button>
              </OverlayTrigger>
            )}
            {(checkRole?.invoice?.includes("delete") ||
              checkRole?.invoice?.includes("Delete")) && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip">Delete invoice</Tooltip>}
              >
                <Button
                  variant="light"
                  size="sm"
                  className="btn-icon text-danger mx-1"
                  data-id={row.id}
                  data-action="confirmBeforeDeletion"
                >
                  <BsTrash3 size={15} />
                </Button>
              </OverlayTrigger>
            )}
          </div>
        );
      },
    },
  ];
  const downloadAsPdf = (id) => {
    navigate(`view/${id}`, {
      state: {
        download: "true",
      },
    });
  };
  const handleDuplicate = (id, requestType = false) => {
    navigate(
      `/invoices/create-duplicate-invoice/${
        requestType ? selectedItems[0] : id
      }`
    );
  };
  const confirmBeforeDeletion = (id, requestType = false) => {
    setDeleteInvoiceAction({
      ...deleteInvoiceAction,
      showConfirmationModal: true,
      idsToDelete: requestType ? selectedItems : [id],
    });
  };

  const deleteInvoice = async () => {
    await axios
      .post(
        `${
          process.env.REACT_APP_API_BASE_URL +
          process.env.REACT_APP_DELETE_INVOICE
        }`,
        {
          ids: deleteInvoiceAction.idsToDelete,
        }
      )
      .then((res) => {
        if (res?.status === 200) {
          dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
          setDeleteInvoiceAction({
            ...deleteInvoiceAction,
            showConfirmationModal: false,
            idsToDelete: [],
          });
          toast.success(
            checkedCheckBoxes.length > 1
              ? "Invoices deleted successfully"
              : "Invoice deleted successfully",
            {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          checkAll.checked = false;
        }
      })
      .catch((error) => {
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.message ||
            error?.response?.data?.error ||
            error?.data?.error,
        });
      });
  };

  const staticRanges = [
    ...defaultStaticRanges,
    ...createStaticRanges([
      {
        key: "thisYear",
        label: "This Financial Year",
        range: () => ({
          startDate: startOfYear(new Date()),
          endDate: endOfYear(new Date()),
        }),
      },
      {
        key: "pastYear",
        label: "Past Financial Year",
        range: () => ({
          startDate: startOfYear(subYears(new Date(), 1)),
          endDate: endOfYear(subYears(new Date(), 1)),
        }),
      },
    ]),
  ].splice(2, 12);

  return (
    <>
      <Head title="All Invoices">
        {(checkRole?.invoice?.includes("delete") ||
          checkRole?.invoice?.includes("Delete")) && (
          <Button
            className="btn btn-danger btn-sm me-1"
            style={
              checkedCheckBoxes.length
                ? { display: "block" }
                : { display: "none" }
            }
            id="multipleDeleteBtn"
            onClick={() => confirmBeforeDeletion("", 1)}
          >
            {checkedCheckBoxes.length > 1
              ? "Delete Invoices"
              : "Delete Invoice"}
          </Button>
        )}
        {(checkRole?.invoice?.includes("create") ||
          checkRole?.invoice?.includes("Create")) && (
          <Button
            variant="primary"
            className="btn-sm me-1"
            style={
              checkedCheckBoxes.length === 1
                ? { display: "block" }
                : { display: "none" }
            }
            id="duplicateInvoice"
            onClick={() => handleDuplicate("", 1)}
          >
            Create Duplicate Invoice
          </Button>
        )}
        {(checkRole?.invoice?.includes("create") ||
          checkRole?.invoice?.includes("Create")) && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip">Click to create new Invoice</Tooltip>
            }
          >
            <Button
              variant="primary"
              className="btn-sm"
              onClick={() => navigate("/create-new-invoice")}
            >
              Create new Invoice
            </Button>
          </OverlayTrigger>
        )}
      </Head>
      <Card className="border-0">
        <Card.Header className="row d-flex justify-content-around align-items-center g-0">
          <Col>Filters</Col>

          <Col xs="auto" className="me-3 position-relative">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip">
                  Click to select the amount range
                </Tooltip>
              }
            >
              <Form.Select
                size="sm"
                aria-label="Default select example"
                onChange={(e) =>
                  setAdditionalFilter({
                    ...additionalFilter,
                    requestEvent: e.target.value,
                    amountRange: e.target.value,
                  })
                }
              >
                <option value="">Amount Range</option>
                <option value="0 | 1000">Less Then ₹1,000</option>
                <option value="1000 | 2500">Between ₹1,000 - ₹2,500</option>
                <option value="2500 | 5000">Between ₹2,500 - ₹5,000</option>
                <option value="5000 | 10000">Between ₹5,000 - ₹10,000</option>
                <option value="custom">Custom Range</option>
              </Form.Select>
            </OverlayTrigger>
          </Col>

          {additionalFilter?.requestEvent === "custom" ? (
            <Col xs="auto" className="me-4 ms-2">
              <MultiRange
                min={200}
                max={2000}
                amountRange={(value) =>
                  setAdditionalFilter({
                    ...additionalFilter,
                    amountRange: `${value?.ranges[1]} | ${value?.ranges[2]}`,
                  })
                }
              />
            </Col>
          ) : null}
          <Col xs="auto me-3">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip">
                  Click to select the payment mode
                </Tooltip>
              }
            >
              <Form.Select
                size="sm"
                aria-label="Default select example"
                onChange={(e) =>
                  setAdditionalFilter({
                    ...additionalFilter,
                    paymentMode: e.target.value,
                  })
                }
              >
                <option value="">Payment Mode</option>
                {paymentModes.map((items) => (
                  <option value={items}>{items}</option>
                ))}
              </Form.Select>
            </OverlayTrigger>
          </Col>
          <Col xs="auto" className=" position-relative">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip">
                  Click to select the date range
                </Tooltip>
              }
            >
              <Dropdown autoClose="outside">
                <Dropdown.Toggle
                  variant="secondary"
                  id="dropdown-basic"
                  size="sm"
                >
                  {`${moment(dateRange[0].startDate).format(
                    "DD MMM, YYYY"
                  )} - ${moment(dateRange[0].endDate).format("DD MMM, YYYY")}`}
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0 overflow-hidden">
                  <Dropdown.Item className="p-0 ">
                    <DateRangePicker
                      ranges={dateRange}
                      onChange={handleSelect}
                      editableDateInputs={true}
                      staticRanges={staticRanges}
                      maxDate={new Date()}
                    />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </OverlayTrigger>
          </Col>
        </Card.Header>
        <Card.Body>
          <DataTables
            url={`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_INVOICE_LIST}`}
            columns={columns}
            defaultSortedColumnIndex={3}
            downloadAsPdf={downloadAsPdf}
            confirmBeforeDeletion={confirmBeforeDeletion}
            setSelectedItems={setSelectedItems}
            additionalFilter={additionalFilter}
            handleDuplicate={handleDuplicate}
          />
        </Card.Body>
        <ToastContainer style={{ top: "60px" }} />
      </Card>
      <Modal show={deleteInvoiceAction.showConfirmationModal} centered>
        <Modal.Header className="border-0 justify-content-center text-danger fw-bold fs-6">
          Warning!
        </Modal.Header>
        <Modal.Body
          className="text-center py-4"
          style={{
            color: "black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Are you sure you want to delete the selected{" "}
          {checkedCheckBoxes.length > 1 ? "invoices" : "invoice"} ?
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            size="sm"
            variant="secondary"
            onClick={() =>
              setDeleteInvoiceAction({
                ...deleteInvoiceAction,
                showConfirmationModal: false,
                idsToDelete: [],
              })
            }
          >
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={() => deleteInvoice()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modals showModal={showPopup} handleClose={handlePopupClose} />
    </>
  );
}
