import axios from "axios";
import Head from "../components/NavBar/Head";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import {
  Col,
  Form,
  InputGroup,
  Modal,
  Button,
  Row,
  Image,
} from "react-bootstrap";
import Modals from "../components/Modal";
import ReactDOMServer from "react-dom/server";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { SET_REFRESH_DATA_TABLE } from "../store/Theme/ThemeActionTypes";
import DataTables from "../components/DataTables";
import { BsEye, BsPen, BsTrash3 } from "react-icons/bs";
import { FETCH_MEDIA_SUCCESS } from "../store/Media/MediaActionTypes";

const Media = () => {
  const [media, setMedia] = useState(null);
  const [showPopup, setShowPopup] = useState({ is: false, msg: "" });
  const [viewMedia, setViewMedia] = useState(false);
  const { userInfo } = useSelector((state) => state?.userInfo);
  const dispatch = useDispatch();
  const formatDate = userInfo?.account_preferences?.date_formate;

  const columns = [
    {
      data: "id",
      class: "d-none",
    },
    {
      data: "extension",
      class: "text-left align-middle",
      width: "150px",
      title: "File",
      render: function (data, type, row) {
        return ReactDOMServer.renderToString(
          <div className="text-right">
            <Image
              src={`${process.env.REACT_APP_API_BASE_URL?.replace(
                "/api",
                "/public/uploads"
              )}${row?.url}`}
              width="130px"
              thumbnail
            />
          </div>
        );
      },
    },
    {
      data: "title",
      class: "text-left align-middle",
      title: "Title",
    },
    {
      data: "extension",
      class: "text-left align-middle",
      title: "File Type",
    },

    {
      data: "created_at",
      class: "text-left align-middle",
      title: "Created On",
      render: function (data, type, row) {
        if (row["created_at"] === null) return "-";
        return `${moment(row["created_at"] || row["updated_at"]).format(
          `${
            formatDate === null || formatDate === undefined
              ? "DD MMM, YYYY"
              : formatDate
          }`
        )}`;
      },
    },

    {
      data: null,
      class: "text-left align-middle  ",
      title: "Actions",
      width: "150px",
      orderable: false,
      render: function (data, type, row) {
        return ReactDOMServer.renderToString(
          <div className="text-right">
            <Button
              variant="light"
              size="sm"
              className="btn-icon mx-1"
              data-id={JSON.stringify(row?.url)}
              data-action="handleViewMedia"
            >
              <BsEye size={17} />
            </Button>
          </div>
        );
      },
    },
  ];

  const handleViewMedia = (url) => {
    setViewMedia(
      `${process.env.REACT_APP_API_BASE_URL?.replace(
        "/api",
        "/public/uploads"
      )}${url?.replace(/^"|"$/g, "")}`
    );
  };

  const handlePopupClose = () => {
    setShowPopup({
      ...showPopup,
      is: false,
      msg: "",
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleFileInput = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    uploadImage([file]);
    reader.readAsDataURL(file);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    uploadImage([...e.dataTransfer.files]);
  };

  const allowDrop = (e) => {
    e.preventDefault();
  };
  const uploadImage = async (droppedFiles) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_UPLOAD_MEDIA}`,
        { url: droppedFiles },
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set proper content type
          },
        }
      );
      // Clear form fields after successful upload
      toast.success("Media uploaded successfully", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
    } catch (error) {
      setShowPopup({
        ...showPopup,
        is: true,
        msg: "Error uploading media",
      });
    }
  };

  return (
    <>
      <h3 className="m-0">Media</h3>
      <p className="m-0">Drag and Drop Files Here</p>
      <div
        className="mt-4"
        onDrop={handleDrop}
        onDragOver={allowDrop}
        style={{
          border: 0,
        }}
      >
        <Row className="m-0 g-2">
          <Col xs={12}>
            <Card className="border-0 shadow-sm">
              <Card.Body>
                <DataTables
                  url={`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_FETCH_MEDIA}`}
                  columns={columns}
                  defaultSortedColumnIndex={0}
                  handleViewMedia={handleViewMedia}
                  dispatchCallType={FETCH_MEDIA_SUCCESS}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>{" "}
      <Modal
        size="lg"
        center
        show={viewMedia !== false}
        onHide={() => setViewMedia(false)}
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title style={{ fontSize: "18px" }}>Media</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={`${viewMedia}`} alt="img" width="100%" height="100%"></img>
        </Modal.Body>
      </Modal>
      <ToastContainer style={{ top: "60px" }} />
      <Modals showModal={showPopup} handleClose={handlePopupClose} />
    </>
  );
};

export default Media;
