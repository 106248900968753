import { FETCH_MEDIA_SUCCESS } from "./MediaActionTypes";

const initialState = { media: [] };

export const MediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MEDIA_SUCCESS:
      return { ...state, media: action.data };

    default:
      return state;
  }
};
