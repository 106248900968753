import React, { useEffect, useState } from 'react';

const generateInvoiceNumber = () => {
  // Retrieve the current date
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed
  const day = currentDate.getDate();

  // Concatenate the date parts to form the invoice number
  const invoiceNumber = `${year}${month}${day}`;

  return invoiceNumber;
};

const InvoiceComponent = () => {
  const [invoiceNumber, setInvoiceNumber] = useState('');

  useEffect(() => {
    // Retrieve the invoice number from localStorage
    const storedInvoiceNumber = localStorage.getItem('invoiceNumber');

    // Check if the stored invoice number matches the current date
    if (storedInvoiceNumber) {
      const currentDate = generateInvoiceNumber();
      if (storedInvoiceNumber.startsWith(currentDate)) {
        setInvoiceNumber(storedInvoiceNumber);
        return;
      }
    }

    // Generate a new invoice number for the current date
    const newInvoiceNumber = generateInvoiceNumber();

    // Store the new invoice number in localStorage
    localStorage.setItem('invoiceNumber', newInvoiceNumber);

    // Update the invoice number state
    setInvoiceNumber(newInvoiceNumber);
  }, []);

  return (
    <div>
      <h1 style={{marginTop:"200px",color:"black"}}>Invoice Number: {invoiceNumber}</h1>
    </div>
  );
};

export default InvoiceComponent;