import React, { useRef, useEffect } from "react";
import {
  Card,
  Form,
  Row,
  Table,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { BsPercent } from "react-icons/bs";
import generatePDF from "react-to-pdf";
import "../Invoice/Template1.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { ToastContainer } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import AutoComplete from "../AutoComplete/AutoComplete";

export default function VoucherTemplate2(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const ref = React.createRef();
  const { userInfo } = useSelector((state) => state.userInfo);
  const formatDate = userInfo?.account_preferences?.date_formate;

  const {
    requestType,
    isCreateVoucher,
    toWords,
    addFields,
    removeFields,
    disableSubmit,
    formik,
    applyGST,
    applyDIS,
    setApplyGST,
    setApplyDIS,
    handlePaidToChange,
    fromCashBook = false,
    handleUnlinkVoucher,
    handleTemplatesChange,
  } = props;
  let subTotal = 0;
  let total = 0;
  let Quantity = 0;
  let discountVal = 0;
  let appliedgst = 0;
  let applieddiscount = 0;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const componentRef = useRef();
  function numberonly(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9 || .]/.test(ch)) {
      evt.preventDefault();
    }
  }

  function numberonly1(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9]/.test(ch)) {
      evt.preventDefault();
    }
  }
  useEffect(() => {
    if (state?.download === "true") {
      document.getElementById("pdf-button").click();
      navigate("/vouchers");
    }
  }, [state?.download]);

  const {
    touched,
    errors,
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (parseFloat(values.has_discounts) > 0) {
      setApplyDIS(true);
    }
    if (parseFloat(values.has_gst) > 0) {
      setApplyGST(true);
    }
  }, []);

  useEffect(() => {
    if (parseFloat(discountVal) !== parseFloat(values?.total_amount)) {
      setFieldValue("total_amount", discountVal);
    }
  }, [discountVal, applyGST, applyDIS, values]);

  return (
    <div className="main222">
      <Card style={{ width: 800 }}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div ref={componentRef}>
            <div ref={ref}>
              <div className="line my-3"></div>
              <div className="mx-4">
                <Row>
                  <Col className="px-0">
                    <div>
                      <Form.Text
                        className="text1 mt-0 mb-4"
                        // style={{ marginBottom: "15px" }}
                      >
                        Voucher
                      </Form.Text>

                      <Form.Text className="text111 text-start my-2 d-block ">
                        PAID TO:
                      </Form.Text>
                      {requestType === "edit" || isCreateVoucher ? (
                        <AutoComplete
                          autoComplete="off"
                          requestType="bills"
                          searchingFor="paid_to"
                          callbackFieldValue={handlePaidToChange}
                          fieldValue={values.paid_to}
                          className="mt-0 mb-2 w-100"
                          name="paid_to"
                          id="paid_to"
                          placeholder="Enter Company Name"
                          readOnly={
                            !(requestType === "edit" || isCreateVoucher)
                          }
                        />
                      ) : (
                        <Form.Text
                          className="text4 text-start"
                          style={{ width: "100%" }}
                        >
                          {values?.paid_to}
                        </Form.Text>
                      )}
                      {touched.paid_to && errors.paid_to && (
                        <Form.Control.Feedback
                          className="d-block "
                          type="invalid"
                        >
                          {errors.paid_to}
                        </Form.Control.Feedback>
                      )}
                      <Form.Text className="text111 text-start my-2 d-block ">
                        ADDRESS:
                      </Form.Text>
                      {requestType === "edit" || isCreateVoucher ? (
                        <AutoComplete
                          requestType="bills"
                          searchingFor="address"
                          name="address"
                          callbackFieldValue={(e) => {
                            setFieldValue("address", e.target.value);
                          }}
                          fieldValue={values.address}
                          as="textarea"
                          rows={1}
                          required
                          className="mt-0 w-100"
                          placeholder="Enter Address"
                          readOnly={
                            !(requestType === "edit" || isCreateVoucher)
                          }
                        />
                      ) : (
                        <Form.Text
                          className="text4 text-start "
                          style={{ width: "100%" }}
                        >
                          {values?.address}
                        </Form.Text>
                      )}
                      {touched.address && errors.address && (
                        <Form.Control.Feedback
                          className="d-block"
                          type="invalid"
                        >
                          {errors.address}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Col>
                  <Col className="px-0 ">
                    <div className="text11 ">
                      {" "}
                      {userInfo?.account_preferences?.attached_logo ? (
                        <img
                          alt="temp"
                          src={`
                              ${process.env.REACT_APP_API_BASE_URL_IMAGE}${userInfo?.account_preferences?.attached_logo}
                            `}
                          style={{
                            height: "60px",
                          }}
                        />
                      ) : (
                        "{Logo}"
                      )}
                    </div>

                    <Form.Text
                      className="text111 my-1"
                      style={{ lineHeight: "1.2" }}
                    >
                      ADDRESS:
                    </Form.Text>
                    <Form.Text className="text11" style={{ lineHeight: "1.2" }}>
                      {userInfo?.account_preferences?.address
                        ? `${userInfo?.account_preferences?.address}, ${userInfo?.account_preferences?.city}, ${userInfo?.account_preferences?.state}`
                        : "{Conpany address, City, State}"}
                    </Form.Text>
                    <Form.Text className="text11" style={{ lineHeight: "1.2" }}>
                      {userInfo?.account_preferences?.pincode
                        ? userInfo?.account_preferences?.pincode
                        : "{Pincode}"}
                    </Form.Text>
                    <Form.Text className="text11" style={{ lineHeight: "1.2" }}>
                      {userInfo?.account_preferences?.contact_no
                        ? `${userInfo?.account_preferences?.contact_no}`
                        : "{Contact number}"}
                      {userInfo?.account_preferences?.alt_contact_no
                        ? `, ${userInfo?.account_preferences?.alt_contact_no}`
                        : ""}
                    </Form.Text>
                    <Form.Text className="text11" style={{ lineHeight: "1.2" }}>
                      {userInfo?.account_preferences?.email_address
                        ? userInfo?.account_preferences?.email_address
                        : "{Email address}"}
                    </Form.Text>
                    {!isCreateVoucher && (
                      <>
                        <Form.Text
                          className="text111 my-2"
                          style={{ lineHeight: "1.2" }}
                        >
                          VOUCHER:
                        </Form.Text>
                        <Form.Text
                          className="text11 my-1"
                          style={{ lineHeight: "1.2" }}
                        >
                          {values?.voucher_no}
                        </Form.Text>
                      </>
                    )}

                    <Form.Text className="text111 text-end my-1 ">
                      DATE:
                    </Form.Text>
                    {requestType === "edit" || isCreateVoucher ? (
                      <div className="text-end">
                        <DatePicker
                          wrapperClassName="datewrapper"
                          customInput={
                            <Form.Control
                              aria-describedby="basic-addon1"
                              className="my-1 text-end w-100"
                            />
                          }
                          required
                          maxDate={new Date()}
                          name="date"
                          id="date"
                          // change
                          dateFormat={
                            formatDate === null || formatDate === undefined
                              ? "dd MMM, yyyy"
                              : formatDate
                                  ?.replace(/D/g, "d")
                                  ?.replace(/Y/g, "y")
                          }
                          selected={new Date(values?.date)}
                          onChange={(e) =>
                            setFieldValue(
                              "date",
                              moment(e).format("YYYY-MM-DD")
                            )
                          }
                          readOnly={
                            !(requestType === "edit" || isCreateVoucher)
                          }
                        />

                        <Form.Control.Feedback
                          className="d-block mb-1"
                          type="invalid"
                        >
                          {errors.date}
                        </Form.Control.Feedback>
                      </div>
                    ) : (
                      <Form.Text className="text11 my-0">
                        {moment(values?.date).format(
                          `${
                            formatDate === null || formatDate === undefined
                              ? "DD MMM, YYYY"
                              : formatDate
                          }`
                        )}
                      </Form.Text>
                    )}
                  </Col>
                </Row>
              </div>

              <div className="mx-4">
                <Table striped hover className="mt-1">
                  <thead>
                    <tr>
                      <th className="text9 text-start">Details</th>
                      <th
                        className="text9 text-start"
                        style={{ width: "135px" }}
                      >
                        Item Price
                      </th>
                      <th
                        className="text9 text-start"
                        style={{ width: "120px" }}
                      >
                        Quantity
                      </th>
                      <th
                        className="text9 text-start"
                        style={{
                          width: "140px",
                        }}
                      >
                        Payments
                      </th>

                      {(requestType === "edit" ||
                        (isCreateVoucher &&
                          values?.bill_description?.length > 1)) && (
                        <th className="text9 text-right "></th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {values.bill_description?.map((row, index) => {
                      total +=
                        row.quantity || row.item_price
                          ? parseFloat(row.quantity * row.item_price)
                          : 0;
                      subTotal = total;
                      Quantity += row.quantity ? parseFloat(row.quantity) : 0;

                      appliedgst = (subTotal * (values?.has_gst || 0)) / 100;
                      applieddiscount =
                        (subTotal * (values?.has_discounts || 0)) / 100;

                      discountVal = (
                        parseFloat(subTotal) +
                        parseFloat(applyGST ? appliedgst : 0) -
                        parseFloat(applyDIS ? applieddiscount : 0)
                      ).toFixed(2);
                      return (
                        <tr key={index} className="temp1row">
                          <td>
                            {requestType === "edit" || isCreateVoucher ? (
                              <AutoComplete
                                requestType="billdescriptions"
                                searchingFor="item_description"
                                name={`bill_description.${index}.item_description`}
                                callbackFieldValue={(e) =>
                                  setFieldValue(
                                    `bill_description.${index}.item_description`,
                                    e.target.value
                                  )
                                }
                                fieldValue={row.item_description}
                                as="textarea"
                                rows={1}
                                required
                                readOnly={
                                  !(requestType === "edit" || isCreateVoucher)
                                }
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `bill_description.${index}.item_description`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `bill_description.${index}.item_description`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.bill_description &&
                              touched.bill_description[index]
                                ?.item_description &&
                              errors.bill_description &&
                              errors.bill_description[index]
                                ?.item_description && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.bill_description &&
                                    errors.bill_description[index]
                                      ?.item_description}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateVoucher ? (
                              <Form.Control
                                maxLength={7}
                                required
                                rows={1}
                                readOnly={
                                  !(requestType === "edit" || isCreateVoucher)
                                }
                                onKeyPress={numberonly}
                                name="item_price"
                                {...getFieldProps(
                                  `bill_description.${index}.item_price`
                                )}
                                type="text"
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `bill_description.${index}.item_price`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `bill_description.${index}.item_price`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.bill_description &&
                              touched.bill_description[index]?.item_price &&
                              errors.bill_description &&
                              errors.bill_description[index]?.item_price && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.bill_description &&
                                    errors.bill_description[index]?.item_price}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateVoucher ? (
                              <Form.Control
                                maxLength={4}
                                required
                                rows={1}
                                readOnly={
                                  !(requestType === "edit" || isCreateVoucher)
                                }
                                onKeyPress={numberonly1}
                                name="quantity"
                                {...getFieldProps(
                                  `bill_description.${index}.quantity`
                                )}
                                type="text"
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `bill_description.${index}.quantity`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `bill_description.${index}.quantity`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.bill_description &&
                              touched.bill_description[index]?.quantity &&
                              errors.bill_description &&
                              errors.bill_description[index]?.quantity && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.bill_description &&
                                    errors.bill_description[index]?.quantity}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateVoucher ? (
                              <Form.Control
                                onKeyPress={numberonly}
                                value={(row.item_price * row.quantity).toFixed(
                                  2
                                )}
                                rows={1}
                                readOnly
                                name="Payments"
                                type="text"
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                              >
                                {(row.item_price * row.quantity).toFixed(2)}
                              </Form.Text>
                            )}
                          </td>
                          {(requestType === "edit" ||
                            (isCreateVoucher &&
                              values?.bill_description?.length > 1)) && (
                            <td className="text-center fs-5 pt-3">
                              <AiOutlineClose
                                className="text-danger mt-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => removeFields(index, row?.id)}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Row className="mt-1">
                  <Col sm={6} className="px-0">
                    <Form.Text className="text111 text-start p-0 my-1">
                      Mode of Payment :
                    </Form.Text>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to select the payment mode
                        </Tooltip>
                      }
                    >
                      {requestType === "edit" || isCreateVoucher ? (
                        <Form.Select
                          className=" my-2"
                          style={{
                            maxWidth: "240px",

                            // fontWeight: "400",
                            fontSize: "15px",
                            marginBottom: "10px",
                          }}
                          required
                          name="payment_mode"
                          value={values?.payment_mode}
                          onChange={(e) => {
                            setFieldValue("payment_mode", e.target.value);
                          }}
                          disabled={
                            !(requestType === "edit" || isCreateVoucher)
                          }
                        >
                          <option value="">Select Payment Mode</option>
                          <option value="Bank">Bank</option>
                          <option value="Cash">Cash</option>
                          <option value="Card">Card</option>
                          <option value="Cheque">Cheque</option>
                          <option value="UPI">UPI</option>
                        </Form.Select>
                      ) : (
                        <Form.Text
                          className="text4 text-start"
                          style={{ width: "100%" }}
                        >
                          {values?.payment_mode}
                        </Form.Text>
                      )}
                    </OverlayTrigger>
                    {touched.payment_mode && errors.payment_mode && (
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {errors.payment_mode}
                      </Form.Control.Feedback>
                    )}
                    {values?.payment_mode !== "Cash" &&
                      values?.payment_mode !== "" && (
                        <Col className="my-1">
                          <Form.Text className="text111 text-start p-0 mb-1 ">
                            {values?.payment_mode === "Bank"
                              ? "Transaction Id:"
                              : values?.payment_mode === "Cheque"
                              ? "Cheque Number:"
                              : values?.payment_mode === "Card"
                              ? "Enter last 4 digits of your card:"
                              : values?.payment_mode === "UPI"
                              ? "UPI Reference Id:"
                              : ""}
                          </Form.Text>
                          {requestType === "edit" || isCreateVoucher ? (
                            <Form.Control
                              className=" my-2 "
                              style={{ maxWidth: "240px" }}
                              maxLength={25}
                              required
                              name="payment_mode_info"
                              readOnly={
                                !(requestType === "edit" || isCreateVoucher)
                              }
                              value={values.payment_mode_info}
                              onChange={(e) => {
                                setFieldValue(
                                  "payment_mode_info",
                                  e.target.value
                                );
                              }}
                              placeholder={
                                values?.payment_mode === "Bank"
                                  ? "Enter the Transaction Id:-"
                                  : values?.payment_mode === "Cash"
                                  ? "Enter Amount:-"
                                  : values?.payment_mode === "Cheque"
                                  ? "Enter Cheque Number:-"
                                  : values?.payment_mode === "Card"
                                  ? "Enter last 4 digits of your card:-"
                                  : values?.payment_mode === "UPI"
                                  ? "Enter UPI Reference Id:-"
                                  : ""
                              }
                            />
                          ) : (
                            <Form.Text
                              className="text4 text-start"
                              style={{ width: "100%" }}
                            >
                              {values.payment_mode_info}
                            </Form.Text>
                          )}
                          {touched.payment_mode_info &&
                            errors.payment_mode_info && (
                              <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                              >
                                {errors.payment_mode_info}
                              </Form.Control.Feedback>
                            )}
                        </Col>
                      )}
                  </Col>
                  <Col sm={6} className="px-0">
                    <Row className="d-flex">
                      <Col sm={5} xs={5} className="p-0">
                        {" "}
                        <Form.Text className="text111 text-start">
                          Sub Total
                        </Form.Text>
                      </Col>
                      <Col xs={7} className="text-end">
                        <Form.Text className="sizewt">
                          {`₹${total.toFixed(2)}`}
                        </Form.Text>
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col sm={5} xs={5} className="p-0">
                        <Form.Text className="text111 text-start">
                          Amount in Words:
                        </Form.Text>
                      </Col>
                      <Col sm={7} xs={7}>
                        <Form.Text className=" sizewt text-end d-block">
                          {toWords.convert(parseInt(Math.round(total)))}
                        </Form.Text>
                      </Col>
                    </Row>

                    {!(
                      requestType === "view" &&
                      !isCreateVoucher &&
                      !values?.has_gst
                    ) && (
                      <Row className="m-0">
                        <Col sm={5} xs={5} className="p-0">
                          <Form.Group controlId="ApplyGST" md="auto">
                            <Form.Check
                              className=" text-start sizewt"
                              checked={applyGST}
                              onChange={() => setApplyGST(!applyGST)}
                              type="checkbox"
                              label="Apply GST"
                              disabled={
                                !(requestType === "edit" || isCreateVoucher)
                              }
                            />
                          </Form.Group>
                        </Col>
                        {applyGST && (
                          <>
                            <Col
                              sm={3}
                              xs={3}
                              className=" d-flex flex-row  pe-0 text-start px-0"
                            >
                              <div>
                                <Form.Control
                                  className="gstdis shadow-none  text-end sizewt"
                                  name="has_gst"
                                  maxLength={5}
                                  type="number"
                                  value={values?.has_gst}
                                  readOnly={
                                    !(requestType === "edit" || isCreateVoucher)
                                  }
                                  onChange={(e) => {
                                    setFieldValue("has_gst", e.target.value);
                                  }}
                                />
                              </div>
                              <div className="d-flex flex-row sizewt ">
                                {" "}
                                <BsPercent className="mt-1 text-end" />
                                &nbsp;=&nbsp;
                              </div>
                            </Col>
                            <Col sm={4} xs={4} className="text-end ">
                              <Form.Text className="sizewt">
                                {`₹${appliedgst.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </>
                        )}
                      </Row>
                    )}
                    {!(
                      requestType === "view" &&
                      !isCreateVoucher &&
                      !values?.has_discounts
                    ) && (
                      <Row className="m-0">
                        <Col sm={5} xs={5} className="p-0">
                          <Form.Group controlId="applyDIS" md="auto">
                            <Form.Check
                              className="sizewt text-start"
                              disabled={
                                !(requestType === "edit" || isCreateVoucher)
                              }
                              checked={applyDIS}
                              onChange={() => setApplyDIS(!applyDIS)}
                              type="checkbox"
                              label="Apply Discount"
                            />
                          </Form.Group>
                        </Col>
                        {applyDIS && (
                          <>
                            <Col
                              sm={3}
                              xs={3}
                              className=" d-flex flex-row  pe-0 text-start px-0"
                            >
                              <div>
                                <Form.Control
                                  className="gstdis shadow-none  text-end sizewt"
                                  maxLength={3}
                                  type="number"
                                  readOnly={
                                    !(requestType === "edit" || isCreateVoucher)
                                  }
                                  value={values?.has_discounts}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "has_discounts",
                                      e.target.value
                                    );
                                  }}
                                  name="disctval"
                                />
                              </div>
                              <div className="d-flex flex-row sizewt">
                                {" "}
                                <BsPercent className="mt-1" />
                                &nbsp;=&nbsp;
                              </div>
                            </Col>
                            <Col sm={4} xs={4} className="text-end ">
                              <Form.Text className="sizewt">
                                {`₹${applieddiscount.toFixed(2)}`}
                              </Form.Text>
                            </Col>
                          </>
                        )}
                      </Row>
                    )}
                  </Col>
                </Row>
              </div>

              <Row className="mt-3">
                <Col
                  className="d-flex align-items-center ps-4 pb-1"
                  style={{ backgroundColor: "#e0dff2" }}
                  sm={6}
                >
                  {!(
                    requestType === "view" &&
                    !isCreateVoucher &&
                    !values?.note
                  ) && (
                    <div style={{ width: "100%" }}>
                      <Form.Text className="text111 text-start my-1 ">
                        Notes:
                      </Form.Text>
                      {requestType === "edit" || isCreateVoucher ? (
                        <AutoComplete
                          requestType="bills"
                          searchingFor="note"
                          callbackFieldValue={(e) =>
                            setFieldValue("note", e.target.value)
                          }
                          fieldValue={values.note}
                          className="my-2 w-100"
                          name="note"
                          id="note"
                          placeholder="Write something here"
                          readOnly={
                            !(requestType === "edit" || isCreateVoucher)
                          }
                        />
                      ) : (
                        <Form.Text
                          className="text4 border-0  text-start"
                          style={{ width: "100%" }}
                        >
                          {values && values?.note}
                        </Form.Text>
                      )}
                    </div>
                  )}
                </Col>

                <Col
                  className="d-flex justify-content-between d-flex align-items-center"
                  sm={6}
                  style={{ backgroundColor: "#5e58d1", paddingRight: "37px" }}
                >
                  <Form.Text className="total1 ms-2">Total</Form.Text>
                  <Form.Text className="total1 ">
                    {`₹${parseFloat(discountVal).toFixed(2)}`}
                  </Form.Text>
                </Col>
              </Row>
            </div>
            {requestType === "view" && !isCreateVoucher && (
              <div style={{ marginTop: "100px" }}>
                <Row>
                  <Col>
                    <h6>
                      Authorized By:
                      <div
                        style={{
                          marginLeft: "130px",
                          borderBottom: "2px solid #333",
                        }}
                      ></div>
                    </h6>
                  </Col>
                  <Col>
                    <h6>
                      Recieved By:
                      <div
                        style={{
                          marginLeft: "110px",
                          borderBottom: "2px solid #333",
                        }}
                      ></div>
                    </h6>
                  </Col>
                </Row>
              </div>
            )}
          </div>

          <Row className="position-sticky bottom-0 p-2 m-0 my-3">
            {requestType === "edit" || isCreateVoucher ? (
              <>
                <Col className="centerBtn">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Click to change template
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="secondary"
                      style={{ width: "100%" }}
                      onClick={() => {
                        if (requestType === "edit") {
                          handleTemplatesChange(true);
                        } else {
                          navigate("/voucherTemplate", {
                            state: {
                              values: formik?.values,
                              requestType: requestType || isCreateVoucher,
                            },
                          });
                        }
                      }}
                    >
                      Change Template
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col className="centerBtn">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">Click to add row</Tooltip>
                    }
                  >
                    <Button
                      variant="secondary"
                      style={{ width: "100%" }}
                      onClick={addFields}
                    >
                      Add Row
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col className="centerBtn">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Click to submit the data to all voucher
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="primary"
                      type="submit"
                      style={{ width: "100%" }}
                      disabled={disableSubmit}
                    >
                      {disableSubmit ? (
                        <div className="d-flex justify-content-center">
                          <div
                            className="spinner-border"
                            role="status"
                            style={{ height: "21px", width: "21px" }}
                          ></div>
                        </div>
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </OverlayTrigger>
                  <ToastContainer style={{ top: "60px" }} />
                </Col>
              </>
            ) : (
              <>
                {fromCashBook && (
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to unlink the voucher from cashbook
                        </Tooltip>
                      }
                    >
                      <Button
                        id="pdf-button"
                        onClick={() => {
                          handleUnlinkVoucher();
                        }}
                        variant="primary"
                        style={{
                          width: "100%",
                          marginRight: "3px",
                        }}
                      >
                        Unlink
                      </Button>
                    </OverlayTrigger>
                  </Col>
                )}
                <Col className="centerBtn">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Click to download the data in PDF
                      </Tooltip>
                    }
                  >
                    <Button
                      id="pdf-button"
                      onClick={() => {
                        generatePDF(componentRef, {
                          filename: `${values?.voucher_no}.pdf`,
                        });
                      }}
                      variant={fromCashBook ? "secondary" : "primary"}
                      style={{
                        width: "100%",
                        marginRight: "3px",
                      }}
                    >
                      Download
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col className="centerBtn">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip">
                        Click to print the data in PDF
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="secondary"
                      className=" w-100"
                      style={{
                        width: "100%",
                        marginLeft: "3px",
                        // backgroundColor: "#5e58d1",
                      }}
                      onClick={handlePrint}
                    >
                      Print
                    </Button>
                  </OverlayTrigger>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </Card>
    </div>
  );
}
