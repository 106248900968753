import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { InputGroup } from "react-bootstrap";

function CreateNewUser() {
  const [checkVal, setCheckVal] = useState();
  const [email, setEmail] = useState("");
  const [lastname, setLastname] = useState("");
  const [firstname, setFiestname] = useState("");
  const [password, setPassword] = useState("");
  const [roleid, Setroleid] = useState("");
  const [users, setUsers] = useState("");
  const [confirmpassword, sertconfirmpassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [cpasswordShown, setCPasswordShown] = useState(false);

  const navigate = useNavigate();

  const Submit = async (e) => {
    e.preventDefault();

    if (password !== confirmpassword) {
      alert("Passwords doesn't match");
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_REGISTRATION_URL}`,
          {
            email: email,
            password: password,
            firstname: firstname,
            lastname: lastname,
            role_id: roleid,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success("User Created Successfully", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setEmail("");
            setLastname("");
            setFiestname("");
            setPassword("");
            Setroleid("");
            navigate("/users");
            setCheckVal(true);
          } else {
            toast.error("user not created!", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
    }
  };

  const usersData = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_FETCH_ROLES}`
    );
    setUsers(result?.data.roles);
  };

  useEffect(() => {
    usersData();
  }, []);

  return (
    <>
      <div className="update-profile-page">
        <div className="update-profile-page1">
          <div className="d-flex justify-content-center">
            <Form.Text className="update-profile-text text-align-center mt-5">
              Create New User
            </Form.Text>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <Form onSubmit={Submit} className="create-user-form">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  required
                  name="firstname"
                  type="text"
                  placeholder="firstname"
                  value={firstname}
                  onChange={(e) => setFiestname(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  required
                  name="lastname"
                  type="text"
                  placeholder="lastname"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <InputGroup
                  className="mb-3 rtrtrr"
                  style={{ border: "none", outline: "none" }}
                >
                  <Form.Control
                    required
                    minLength={6}
                    name="password"
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputGroup.Text
                    className="login-hide"
                    onClick={() =>
                      passwordShown
                        ? setPasswordShown(false)
                        : setPasswordShown(true)
                    }
                  >
                    {passwordShown ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Confirm password</Form.Label>
                <InputGroup
                  className="mb-3 rtrtrr"
                  style={{ border: "none", outline: "none" }}
                >
                  <Form.Control
                    required
                    minLength={6}
                    name="password"
                    type={cpasswordShown ? "text" : "password"}
                    placeholder="confirm Password"
                    value={confirmpassword}
                    onChange={(e) => sertconfirmpassword(e.target.value)}
                  />
                  <InputGroup.Text
                    className="login-hide"
                    onClick={() =>
                      cpasswordShown
                        ? setCPasswordShown(false)
                        : setCPasswordShown(true)
                    }
                  >
                    {cpasswordShown ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Select role</Form.Label>
                <Form.Select
                  required
                  onChange={(e) => Setroleid(e.target.value)}
                >
                  <option value="">Select role</option>
                  {users &&
                    users?.map((item, index) => {
                      return <option value={item.id}>{item?.role}</option>;
                    })}
                </Form.Select>
              </Form.Group>

              <div className="d-flex justify-content-center mb-4 mt-4">
                <Button
                  disabled={checkVal}
                  variant="primary"
                  className="button"
                  type="submit"
                >
                  Submit
                </Button>
                <ToastContainer />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewUser;
