import { FETCH_CASHBOOK_ENTRIES_SUCCESS } from "./CashbookActionTypes";

const initialState = { entries: [] };

export const cashbookReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CASHBOOK_ENTRIES_SUCCESS:
      return { ...state, entries: action.data, otherInfo: action.otherInfo };

    default:
      return state;
  }
};
