import React, { useState } from "react";
import "../App.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap//Modal";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import Table from "react-bootstrap/Table";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { AiOutlineClose } from "react-icons/ai";
import { useFormik } from "formik";

const HEAD = [
  { id: "date", key: "Date" },
  { id: "description", key: "Description" },
  { id: "voucher_Id", key: "Voucher No" },
  { id: "credit_Amount", key: "Credit Amount" },
  { id: "debit_Amount", key: "Debit Amount" },
];

const validationSchema = Yup.object().shape({
  multiple: Yup.array()
    .of(
      Yup.object().shape({
        date: Yup.string().required("Date is required"),
        description: Yup.string().required("Detail is required"),
        voucher_Id: Yup.string(),
        // credit_Amount: Yup.string().when(["debit_Amount", "credit_Amount"],(debit_Amount, credit_Amount, schema) => {
        //     if (debit_Amount&&debit_Amount!=='')
        //       return schema
        //     return schema.required("Credit Amount is required"),
        // }),
        // debit_Amount: Yup.string().when(["debit_Amount", "credit_Amount"],(debit_Amount, credit_Amount, schema) => {
        //     if (credit_Amount&&credit_Amount!=='')
        //       return schema
        //     return schema.required("Credit Amount is required"),
        // }),
      })
    )
    .min(1, "At least one item is required"),
});

export default function CashbookEntries(props) {
  const { setAddEntry, addEntry, createOrUpdateCashbookEntry, numberonly } =
    props;
  const [creditordebit, setCreditOrDebit] = useState(false);
  const { userInfo } = useSelector((state) => state?.userInfo);
  const [, setValueUpdater] = useState("");

  // Function to add a new array with the same values

  const formatDate = userInfo?.account_preferences?.date_formate;

  const formik = useFormik({
    initialValues: {
      multiple: [
        {
          date: moment().format("YYYY-MM-DD"),
          description: "",
          voucher_Id: "",
          credit_Amount: "",
          debit_Amount: "",
        },
      ],
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      const entriesValues = formValues?.multiple.map((row) => ({
        date: moment(row?.date).format("YYYY-MM-DD"),
        summary: row?.description,
        voucher_number: `${row?.voucher_Id}`,
        amount: `${row?.credit_Amount || row?.debit_Amount}`,
        transaction_type: row?.credit_Amount ? "1" : "0",
        created_by: userInfo?.user_data?.id,
        bill_image: null,
      }));
      createOrUpdateCashbookEntry(entriesValues);
      setAddEntry(false);
    },
  });

  const removeFields = (index) => {
    if (formik.values.multiple && Array.isArray(formik.values.multiple)) {
      formik.values.multiple.splice(index, 1);
    }
    setValueUpdater(Math.random());
  };
  const addRow = () => {
    const newfield = {
      date: moment().format("YYYY-MM-DD"),
      description: "",
      voucher_Id: "",
      credit_Amount: "",
      debit_Amount: "",
    };
    formik.setFieldValue("multiple", [...formik.values.multiple, newfield]);
    setValueUpdater(Math.random());
  };

  console.log(formik);

  return (
    <>
      <Modal
        size="xl"
        centered
        show={addEntry !== false}
        onHide={() => setAddEntry(false)}
      >
        <Modal.Header>
          <Modal.Title>Add Entries</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated onSubmit={formik.handleSubmit}>
            <Table hover striped className="mt-1">
              <thead>
                <tr>
                  {HEAD.map((row, index) => (
                    <th className="text9" key={index}>
                      {row?.key}
                    </th>
                  ))}
                  {formik?.values?.multiple.length > 1 && (
                    <th className="text9 text-right "></th>
                  )}
                </tr>
              </thead>
              <tbody>
                {formik.values?.multiple.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <InputGroup>
                        <DatePicker
                          name="date"
                          maxDate={new Date()}
                          id="date"
                          dateFormat={
                            formatDate === null || formatDate === undefined
                              ? "dd MMM, yyyy"
                              : formatDate
                                  ?.replace(/D/g, "d")
                                  ?.replace(/Y/g, "y")
                          }
                          selected={
                            new Date(formik?.values?.multiple[index]?.date)
                          }
                          onChange={(e) =>
                            formik?.setFieldValue(
                              `multiple.${index}.date`,
                              moment(e).format("YYYY-MM-DD")
                            )
                          }
                          customInput={
                            <Form.Control aria-describedby="basic-addon1" />
                          }
                        />
                        {/* {formik?.touched?.multiple &&
                          formik?.errors?.multiple &&
                          Object.keys(formik?.errors?.multiple).length > 0 &&
                          formik?.touched?.multiple[index].date &&
                          formik?.errors?.multiple[index].date && (
                            <Form.Control.Feedback
                              className="d-block"
                              type="invalid"
                            >
                              {formik?.errors?.multiple[index].date}
                            </Form.Control.Feedback>
                          )} */}
                      </InputGroup>
                    </td>
                    <td className="w-25">
                      <Form.Control
                        name="description"
                        type="text"
                        value={formik?.values?.multiple[index].description}
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `multiple.${index}.description`,
                            e.target.value
                          );
                        }}
                        rows={1}
                        as="textarea"
                      />

                      {/* {formik?.touched?.multiple &&
                        formik?.errors?.multiple &&
                        Object.keys(formik?.errors?.multiple).length > 0 &&
                        formik?.touched?.multiple[index].description &&
                        formik?.errors?.multiple[index].description && (
                          <Form.Control.Feedback
                            className="d-block"
                            type="invalid"
                          >
                            {formik?.errors?.multiple[index].description}
                          </Form.Control.Feedback>
                        )} */}
                    </td>
                    <td>
                      <Form.Control
                        name="voucher_Id"
                        type="text"
                        value={formik?.values?.multiple[index]?.voucher_Id}
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `multiple.${index}.voucher_Id`,
                            e.target.value
                          );
                        }}
                      />
                      {/* {formik?.touched?.multiple &&
                        formik?.errors?.multiple &&
                        Object.keys(formik?.errors?.multiple).length > 0 &&
                        formik?.touched?.multiple[index].voucher_Id &&
                        formik?.errors?.multiple[index].voucher_Id && (
                          <Form.Control.Feedback
                            className="d-block"
                            type="invalid"
                          >
                            {formik?.errors?.multiple[index].voucher_Id}
                          </Form.Control.Feedback>
                        )} */}
                    </td>
                    <td>
                      <Form.Control
                        onKeyPress={numberonly}
                        name="credit_Amount"
                        type="text"
                        value={formik?.values?.multiple[index]?.credit_Amount}
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `multiple.${index}.credit_Amount`,
                            e.target.value
                          );
                          if (
                            formik?.values?.multiple[index]?.debit_Amount !== ""
                          ) {
                            setCreditOrDebit(true);
                            setAddEntry(false);
                            formik?.setFieldValue(
                              `multiple.${index}.credit_Amount`,
                              ""
                            );
                          }
                        }}
                      />
                      {/* {formik?.touched?.multiple &&
                        formik?.errors?.multiple &&
                        Object.keys(formik?.errors?.multiple).length > 0 &&
                        formik?.touched?.multiple[index].credit_Amount &&
                        formik?.errors?.multiple[index].credit_Amount && (
                          <Form.Control.Feedback
                            className="d-block"
                            type="invalid"
                          >
                            {formik?.errors?.multiple[index].credit_Amount}
                          </Form.Control.Feedback>
                        )} */}
                    </td>
                    <td>
                      <Form.Control
                        onKeyPress={numberonly}
                        name="debit_Amount"
                        type="text"
                        value={formik?.values?.multiple[index]?.debit_Amount}
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `multiple.${index}.debit_Amount`,
                            e.target.value
                          );
                          if (
                            formik?.values?.multiple[index]?.credit_Amount !==
                            ""
                          ) {
                            setAddEntry(false);
                            setCreditOrDebit(true);
                            formik?.setFieldValue(
                              `multiple.${index}.debit_Amount`,
                              ""
                            );
                          }
                        }}
                      />
                      {/* {formik?.touched?.multiple &&
                        formik?.errors?.multiple &&
                        Object.keys(formik?.errors?.multiple).length > 0 &&
                        formik?.touched?.multiple[index].debit_Amount &&
                        formik?.errors?.multiple[index].debit_Amount && (
                          <Form.Control.Feedback
                            className="d-block"
                            type="invalid"
                          >
                            {formik?.errors?.multiple[index].debit_Amount}
                          </Form.Control.Feedback>
                        )} */}
                    </td>
                    {formik?.values?.multiple.length > 1 && (
                      <td className="text-center fs-5 pt-3">
                        <AiOutlineClose
                          className="text-danger mt-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => removeFields(index, row?.id)}
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="d-flex justify-content-between">
              <div>
                {" "}
                <Button
                  size="sm"
                  type="submit"
                  className="mx-2"
                  onClick={() => addRow()} // Pass handleEntries to handleSubmit
                >
                  Add Row
                </Button>
              </div>
              <div>
                {" "}
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => {
                    formik?.resetForm();
                    setAddEntry(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  className="mx-2"
                  disabled={formik?.isSubmitting}
                  onClick={formik?.handleSubmit} // Pass handleEntries to handleSubmit
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={creditordebit}
        onHide={() => setCreditOrDebit(false)}
      >
        <Modal.Header className="border-0 justify-content-center fw-bold fs-6">
          Alert!
        </Modal.Header>
        <Modal.Body className="text-center fs-6">
          Please enter only one of Credit or Debit amount.{" "}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            size="sm"
            variant="secondary"
            className="mx-2"
            onClick={() => {
              setCreditOrDebit(false);
              setAddEntry(true);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
