import { FETCH_INVOICE_SUCCESS } from "../store/Invoice/InvoiceActionTypes";
import moment from "moment";
export const exportBtnType = (
  columnsToExport = [1, 2],
  dispatchCallType,
  handleImportModal
) => [
  {
    extend: "pageLength",
    className: `btn btn-primary btn-sm`,
  },
  // Export options for Excel, CSV, and PDF
  !window.location.pathname.includes("user-roles") && {
    text: "Export",
    className: "btn btn-primary btn-sm",
    extend: "collection",
    buttons: [
      {
        extend: "excel",
        text: "Export to Excel",
        exportOptions: {
          columns: columnsToExport,
          orthogonal: "export",
        },
      },
      {
        extend: "csv",
        text: "Export to CSV",
        exportOptions: {
          columns: columnsToExport,
          orthogonal: "export",
        },
      },
      {
        extend: "pdf",
        text: "Export to PDF",
        exportOptions: {
          columns: columnsToExport,
          orthogonal: "export",
        },
        customize: function (doc) {
          // Get the number of columns in the table
          const numColumns = doc.content[1].table.body[0].length;

          // Calculate the width for each column
          const columnWidth = 100 / numColumns;

          // Set the widths of all columns to fit the full page width
          doc.content[1].table.widths = Array(numColumns).fill(
            columnWidth + "%"
          );

          // Set alignment for each column
          for (let i = 0; i < numColumns; i++) {
            // Center align the title (first row)
            doc.content[1].table.body[0][i].alignment = "center";

            // Left align the data (remaining rows)
            for (let j = 1; j < doc.content[1].table.body.length; j++) {
              doc.content[1].table.body[j][i].alignment = "left";
            }
          }
        },
      },

      // {
      //   text: "Export All Data",
      //   action: function () {
      //     handleCustomExport(); // Call the function to open the modal
      //   },
      // },
    ],
  },
  // Import button
  dispatchCallType === "FETCH_CASHBOOK_ENTRIES_SUCCESS" && {
    className: `btn btn-primary btn-sm`,
    text: "Import",
    action: function () {
      handleImportModal();
    },
  },
];

const transformDate = (date) => moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

// Utility function to transform data
const transformData = (data, dispatchCallType) => {
  const responseData = [...data];
  if (dispatchCallType === "FETCH_INVOICE_SUCCESS") {
    return responseData?.map((row) => ({
      ...row,
      date: transformDate(row.date),
      invoice_no: row?.voucher_no,
      // voucher_no: undefined,
    }));
  }
  // if (dispatchCallType === "FETCH_VOUCHER_SUCCESS")
  //   return responseData?.map((row) => ({
  //     ...row,
  //     date: transformDate(row.date),
  //     voucher_no: row?.invoice_no,
  //     // invoice_no: undefined,
  //   }));
  return responseData?.map((row) => ({
    ...row,
    date: transformDate(row.date),
  }));
};

export const dataTableConfig = (
  url,
  requestType = "GET",
  columns,
  defaultSortedIndex,
  defaultSortedIndexShortType = "desc",
  dispatch,
  dispatchCallType,
  additionalFilter,
  handleImportModal,
  handleCustomExport,
  enableOrdering
) => {
  const sorting =
    dispatchCallType !== "FETCH_CASHBOOK_ENTRIES_SUCCESS"
      ? [[defaultSortedIndex, defaultSortedIndexShortType]]
      : [];
  const columnSorting =
    dispatchCallType !== "FETCH_CASHBOOK_ENTRIES_SUCCESS" ? true : false;

  const columnsToExport = columns
    .map((row, index) =>
      row.data !== null &&
      row.data !== "id" &&
      row.data !== "invoice_no" &&
      row.data !== "voucher_no" &&
      row.data !== "url"
        ? index
        : null
    )
    ?.filter((key) => key !== null);
  return {
    language: {
      paginate: {
        next: "&#8594;", // or '→'
        previous: "&#8592;", // or '←'
      },
      searchPlaceholder: "Search",
      search: "",
      processing:
        '<div class="custom-processing text-primary">Loading...</div>', // Custom processing indication
    },
    dom:
      "<'row g-0'<'col-sm-8'Bl><'col-sm-4'f>>" +
      "<'row g-0 mt-3'<'col-sm-12'tr>>" +
      "<'row g-0 mt-3'<'col-sm-5'i><'col-sm-7'p>>",
    buttons: exportBtnType(
      columnsToExport,
      dispatchCallType,
      handleImportModal,
      handleCustomExport
    )?.filter((row) => typeof row === "object"),
    destroy: true,
    processing: true,
    ordering: enableOrdering,
    serverSide: true,
    pageLength: 10,
    lengthMenu: [
      [10, 25, 50, 100, 500, -1],
      ["10 rows", "25 rows", "50 rows", "100 rows", "500 rows", "Show all"],
    ],
    searching: dispatchCallType !== "FETCH_CASHBOOK_ENTRIES_SUCCESS", // Conditionally show search field

    lengthChange: false,
    stateSave: true,
    ajax: {
      url,
      type: requestType,
      data: function (d) {
        return { ...d, ...additionalFilter };
      },
      dataSrc: function (res) {
        const responseData = { ...res };
        delete responseData["draw"];
        delete responseData["data"];
        delete responseData["input"];
        delete responseData["recordsFiltered"];
        delete responseData["recordsTotal"];
        dispatch({
          type: dispatchCallType || FETCH_INVOICE_SUCCESS,
          data: transformData(res?.data, dispatchCallType),
          otherInfo: responseData,
        });
        return transformData(res?.data, dispatchCallType);
      },
    },
    columns,
    aaSorting: sorting,
    columnDefs: [
      {
        orderable: columnSorting,
        targets: "_all",
      },
    ],
  };
};
