import React from "react";
import { Form } from "react-bootstrap";

function Nopage() {
  return (
    <>
      <Form.Text
        style={{
          color: "black",
          marginTop: "250px",
          display: "flex",
          justifyContent: "center",
          fontSize: "60px",
          fontWeight: "700",
        }}
      >
        Error 404
      </Form.Text>
      <Form.Text
        style={{
          color: "black",
          textAlign: "center",
          fontSize: "60px",
          fontWeight: "700",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Page Not Found
      </Form.Text>
    </>
  );
}

export default Nopage;
