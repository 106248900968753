import React, { useState, useRef, useEffect } from "react";
import Col from "react-bootstrap/Col";
import "../App.css";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Head from "../components/NavBar/Head";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap//Modal";
import { toast, ToastContainer } from "react-toastify";
import { BsEye, BsPen, BsTrash3 } from "react-icons/bs";
import { renderToStaticMarkup } from "react-dom/server";
import DataTables from "../components/DataTables";
import { useForm } from "react-hook-form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { yupResolver } from "@hookform/resolvers/yup";
import { MdOutlinePermMedia } from "react-icons/md";
import * as Yup from "yup";
import axios from "axios";
import { SET_REFRESH_DATA_TABLE } from "../store/Theme/ThemeActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_CASHBOOK_ENTRIES_SUCCESS } from "../store/Cashbook/CashbookActionTypes";
import DatePicker from "react-datepicker";
import moment from "moment";
import AllVouchers from "./AllVouchers";
import FileImporter from "../components/FileImporter";
import { Alert, Dropdown, ModalHeader, SplitButton } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import * as XLSX from "xlsx";
import {
  DateRangePicker,
  createStaticRanges,
  defaultStaticRanges,
} from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { endOfYear, startOfYear, subYears } from "date-fns";
import CustomExport from "../components/CustomExport";
import SelectVouchers from "../components/Voucher/SelectVouchers";
import Modals from "../components/Modal";
import CashbookEntries from "../components/CashbookEntries";

const HEAD = [
  { id: "date", key: "Date" },
  { id: "description", key: "Description" },
  { id: "voucher_Id", key: "Voucher No" },
  { id: "credit_Amount", key: "Credit Amount" },
  { id: "debit_Amount", key: "Debit Amount" },
  { id: "balance", key: "Balance" },
];
const cashbookValidationSchema = Yup.object().shape({
  summary: Yup.string().required().label("Summary"),
  amount: Yup.string().required().label("Amount"),
  transaction_type: Yup.string().required().label("Transaction Type"),
  date: Yup.string().required().label("Date"),
});

export default function CashBook() {
  const { state } = useLocation();

  const fileInputRef = useRef(null);
  const { theme } = useSelector((state) => state);
  const { entries } = useSelector((state) => state.cashbookEntries);
  const { vouchers } = useSelector((state) => state.vouchers);
  const [viewBill, setViewBill] = useState(false);

  const [unlink, setUnlink] = useState({
    isOpen: false,
    id: "",
    msg: "",
    type: "",
    isLoader: false,
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const [addVoucher, setAddVoucher] = useState(false);
  const [impModal, setImpModal] = useState(false);
  const [viewVoucher, setViewVoucher] = useState(false);
  const [importedData, setImportedData] = useState([]);
  const [misMatchAmount, setMisMatchAmount] = useState(false);
  const [addEntry, setAddEntry] = useState(false);
  const [showPopup, setShowPopup] = useState({ is: false, msg: "" });

  const [modalActions, setModalActions] = useState({
    showModal: false,
    title: "In Entry",
  });
  const [isCustomExport, setIsCustomExport] = useState(false);
  const checkAll = document.querySelector(".checkAll");
  const checkedCheckBoxes = document.querySelectorAll(".checkBox:checked");

  const handleCustomExport = () => {
    setIsCustomExport(true);
  };

  const handleCustomExportClose = () => {
    setIsCustomExport(false);
  };
  const [additionalFilter, setAdditionalFilter] = useState({
    lastTenTransaction: true,
  });
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handlePopupClose = () => {
    setShowPopup({
      ...showPopup,
      is: false,
      msg: "",
    });
  };
  const handleSelect = (ranges) => {
    if (
      isNaN(ranges.selection.startDate.getTime()) &&
      isNaN(ranges.selection.endDate.getTime())
    ) {
      setDateRange([ranges.selection]);
      setAdditionalFilter({ lastTenTransaction: true });
    } else {
      setDateRange([ranges.selection]);
      setAdditionalFilter({
        dateRange: `${moment(ranges.selection.startDate).format(
          "YYYY-MM-DD"
        )} | ${moment(ranges.selection.endDate).format("YYYY-MM-DD")}`,
      });
    }
  };
  const [deleteCashbookEntriesAction, setDeleteCashbookEntriesAction] =
    useState({
      showConfirmationModal: false,
    });

  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state?.userInfo);
  const userId = userInfo?.user_data?.id;
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(cashbookValidationSchema),
  });

  const formatDate = userInfo?.account_preferences?.date_formate;
  const columns = [
    {
      data: "id",
      class: "d-none adjust-custom-padding",
    },
    {
      data: null,
      title:
        '<input class="form-check-input checkAll" type="checkbox" data-action="checkAll">',
      width: "20px",
      class: "text-center align-middle adjust-custom-padding",
      orderable: false,

      render: function (data, type, row) {
        return (
          `<input type="checkbox" class="form-check-input checkBox" name="posts[]" value="` +
          row["id"] +
          `" data-action="checkBox">`
        );
      },
    },
    {
      data: "date",
      title: "Date",
      width: "95px",
      class: "text-left align-middle adjust-custom-padding",
      render: function (data, type, row) {
        if (row["date"] === null) return "-";
        return `${moment(row["date"]).format(
          formatDate === undefined || formatDate === null
            ? "DD MMM, YYYY"
            : formatDate
        )}`;
      },
    },
    {
      data: "summary",
      width: "200px",
      title: "Description",
      class: "text-left align-middle adjust-custom-padding",
      render: function (data, type, row) {
        return row?.summary;
      },
    },
    {
      data: "attached_with_invoice",
      title: "Voucher Id",
      // width: "50px",
      class: "text-left align-middle adjust-custom-padding ",
      render: function (data, type, row) {
        if (row.transaction_type === 1) return "";
        return row["attached_with_voucher"] && row["voucher_number"]
          ? renderToStaticMarkup(
              <Button
                variant="light"
                size="sm"
                data-id={row?.voucher_number}
                data-action="handleViewVoucher"
                className="bg-none text-success px-0"
              >
                {row["voucher_number"]}
              </Button>
            )
          : renderToStaticMarkup(
              <Button
                variant="outline-warning"
                size="sm"
                data-id={row.id}
                data-action="handleAddVoucher"
                className="py-1 px-2"
              >
                <small>Add Voucher</small>
              </Button>
            );
      },
    },
    {
      data: "bill_image",
      class: "text-left align-middle adjust-custom-padding",
      title: "Bill",
      // width: "60px",
      render: function (data, type, row) {
        if (row.transaction_type === 1) return "";
        return renderToStaticMarkup(
          row["bill_image"] ? (
            <Button
              variant="light"
              size="sm"
              data-id={row.id}
              data-action="handleViewBill"
              className="btn-icon custom-btn-icon text-success mx-1 "
            >
              <MdOutlinePermMedia size={15} />
            </Button>
          ) : (
            <Button
              variant="outline-warning"
              size="sm"
              data-id={row.id}
              data-action="handleAddBill"
              className="py-1 px-2"
            >
              <small>Attach Bill</small>
            </Button>
          )
        );
      },
    },
    {
      data: "amount",
      title: "Amount (in ₹)",
      // width: "140px",
      class: "text-left align-middle adjust-custom-padding",
      render: function renderAmount(data, type, row) {
        const isCredit = parseInt(row.transaction_type, 10) === 1; // Assuming transaction_type is a string
        const iconColor = isCredit ? "green" : "red";

        const formattedAmount =
          '<span style="display: inline-block; text-align: center;">' +
          row.amount +
          '&nbsp;<span style="color: ' +
          iconColor +
          ';">' +
          (isCredit ? "Cr" : "Dr") +
          "</span></span>";

        return formattedAmount;
      },
    },
    {
      data: "balance",
      title: "Balance (in ₹)",
      // width: "140px",
      class: "text-left align-middle adjust-custom-padding",
      render: function (data, type, row) {
        if (row?.balance) return row?.balance;
        return "-";
      },
    },
    {
      data: null,
      class: "text-left align-middle adjust-custom-padding",
      title: "Actions",
      width: "150px",
      orderable: false,
      render: function (data, type, row) {
        return renderToStaticMarkup(
          <div className="text-right">
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="button-tooltip">
                  Check to select the template as default
                </Tooltip>
              }
            >
              <Button
                variant="light"
                size="sm"
                data-id={row.id}
                data-action="viewCashbookEntry"
                className="btn-icon custom-btn-icon mx-1 "
              >
                <BsEye size={15} />
              </Button>
            </OverlayTrigger>
            <Button
              variant="light"
              size="sm"
              className="mx-1 btn-icon custom-btn-icon"
              data-id={row.id}
              data-action="updateCashbookEntry"
            >
              <BsPen size={15} />
            </Button>
            <Button
              variant="light"
              size="sm"
              className="btn-icon custom-btn-icon text-danger mx-1"
              data-id={row.id}
              data-action="confirmBeforeDeletion"
            >
              <BsTrash3 size={15} />
            </Button>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    if (state?.isCashBook) {
      const voucher = parseFloat(
        vouchers?.find((item) => item.invoice_no === state?.isCashBook)
      );
      callBackHandleChange(voucher?.id, voucher?.total_amount);
    }
  }, [state?.isCashBook]);

  const callBackHandleChange = (id, voucherAmount) => {
    setValue(`voucher_id`, parseInt(id, 10));

    const extractedData = {
      voucher_id: parseInt(id, 10),
      id: getValues("id"),
    };

    setAddVoucher(false);
    if (id) {
      if (voucherAmount !== parseFloat(getValues("amount"))) {
        setMisMatchAmount(voucherAmount);
      } else {
        UpdateVoucherId(extractedData);
      }
    }
  };
  const handleImportModal = () => {
    setImpModal(true);
  };
  const createOrUpdateCashbookEntry = async (cashbook_entry) => {
    setImportedData([]);
    setImpModal(false);
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${
          process.env.REACT_APP_ADD_CASHBOOK
        }${cashbook_entry[0]?.id ? `/${cashbook_entry[0]?.id}` : ""}`,
        { cashbook_entry },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          if (cashbook_entry[0]?.id) {
            toast.success("You have successfully updated cashBook entry", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.success("You have successfully created cashBook entry", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          reset();
          dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
          return response;
        }
      })
      .catch((error) => {
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.error ||
            error?.message,
        });

        return error;
      });
    setModalActions({
      ...modalActions,
      showModal: false,
      title: "",
    });
  };
  const onSubmit = (data = "") => {
    createOrUpdateCashbookEntry([{ ...data, created_by: userId }]);
  };

  const UpdateBillImage = async (data) => {
    if (data?.attachment?.length) {
      data.type = data.attachment[0].type;
      data.name = data.attachment[0].name;
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ATTACH_DOC_WITH_CASHBOOK}`,
        data,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          toast.success("Document attached successfully", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
          reset();
          return response;
        }
      })
      .catch((error) => {
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.error ||
            error?.message,
        });

        return error;
      });
  };

  const handleUnlink = async (id, type) => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${
          type === "bill"
            ? process.env.REACT_APP_REMOVE_DOC_WITH_CASHBOOK
            : process.env.REACT_APP_REMOVE_VOUCHER_WITH_CASHBOOK
        }`,
        { id },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          setUnlink({
            ...unlink,
            isOpen: true,
            isLoader: false,
            msg: "Document remove successfully. Are you want to attached with new one?",
          });

          dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });

          return response;
        }
      })
      .catch((error) => {
        setUnlink({
          ...unlink,
          isOpen: false,
          isLoader: false,
          msg: "",
          id: "",
          type: "",
        });
        reset();
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.error ||
            error?.message,
        });

        return error;
      });
  };
  const UpdateVoucherId = async (data) => {
    data["invoice_id"] = data?.voucher_id;
    setMisMatchAmount(false);
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADD_VOUCHER_WITH_CASHBOOK}`,
        data,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          toast.success("You have successfully update voucher number", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          reset();
          dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
          return response;
        }
      })
      .catch((error) => {
        setShowPopup({
          ...showPopup,
          is: true,
          msg:
            error?.data?.error ||
            error?.response?.data?.error ||
            error?.message,
        });

        return error;
      });
  };
  const CSVimport = async () => {
    if (importedData.length > 0) {
      createOrUpdateCashbookEntry(importedData);
    }
  };
  const confirmBeforeDeletion = (id, requestType = 0) => {
    setDeleteCashbookEntriesAction({
      ...deleteCashbookEntriesAction,
      showConfirmationModal: true,
      idsToDelete: requestType ? selectedItems : [id],
    });
  };
  const handleUnlinkVoucher = () => {
    setUnlink({ ...unlink, isOpen: true });
    setViewVoucher(false);
  };
  const handleAddVoucher = (id, requestType = 0) => {
    setAddVoucher(true);
    const getEntry = entries.find((item) => item.id === parseInt(id));
    setValue("id", parseInt(id, 10));
    setValue("amount", getEntry?.amount);
    setValue("date", getEntry?.date);
  };
  let viewVoucherId;
  const handleViewVoucher = (voucher_no) => {
    const getEntry = entries.find((item) => item.voucher_number === voucher_no);
    viewVoucherId = getEntry?.attached_with_voucher;
    setViewVoucher(getEntry?.attached_with_voucher);
    setUnlink({
      ...unlink,
      id: getEntry?.id?.toString(),
      msg: "Are you sure want to unlink the voucher?",
      type: "voucher",
    });
  };
  const handleAddBill = (id) => {
    setValue("id", parseInt(id, 10));
    document.getElementById("fileInput").click();
  };
  const handleViewBill = (id) => {
    const getEntry = entries.filter((item) => item.id === parseInt(id))[0];
    const { bill_image } = getEntry || {};
    setUnlink({
      ...unlink,
      id: id?.toString(),
      msg: "Are you sure want to unlink the bill?",
      type: "bill",
    });
    setViewBill(bill_image);
  };

  const viewCashbookEntry = (id) => {
    const getEntry = entries.filter((item) => item.id === parseInt(id))[0];
    Object.keys(getEntry).map((item) => setValue(`${item}`, getEntry[item]));
    setModalActions({
      ...modalActions,
      showModal: true,
      title: `Update Cashbook ${
        getValues("transaction_type") === 1 ? `Cash In` : `Cash Out`
      } Entry`,
      submitButtonTitle: "",
    });
  };
  const cancelNewEntriesColumn = (id = "") => {
    setAddEntry(false);
  };

  const updateCashbookEntry = (id) => {
    const getEntry = entries.filter((item) => item.id === parseInt(id))[0];
    Object.keys(getEntry).map((item) => setValue(`${item}`, getEntry[item]));
    setModalActions({
      ...modalActions,
      showModal: true,
      title: `Update Cashbook ${
        getValues("transaction_type") === 1 ? `Cash In` : `Cash Out`
      } Entry`,
      submitButtonTitle: "Update",
    });
  };

  const deleteCashbookEntries = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_DELETE_CASHBOOK_ENTRIES}`,
        {
          id: deleteCashbookEntriesAction.idsToDelete,
        }
      )
      .then((res) => {
        dispatch({ type: SET_REFRESH_DATA_TABLE, data: Math.random() });
        setDeleteCashbookEntriesAction({
          ...deleteCashbookEntriesAction,
          showConfirmationModal: false,
          idsToDelete: [],
        });
        if (res.status === 200) {
          if (checkedCheckBoxes.length > 1) {
            toast.success("You have successfully deleted cashbook entries ", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.success("You have successfully deleted cashbook entry", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }

          checkAll.checked = false;
        }
      });
  };
  const handleFileChange = (e, isupdate = false) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      setValue("type", file.type);
      setValue("name", file.name);
      reader.onload = (event) => {
        const base64String = event.target.result;
        setValue("bill_image", base64String);
        if (isupdate) {
          UpdateBillImage(getValues());
        }
      };

      reader.readAsDataURL(file);
    }
  };
  function numberonly(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9 || .]/.test(ch)) {
      evt.preventDefault();
    }
  }

  const isCorrectFormat = importedData.filter(
    (row) =>
      row.amount === undefined ||
      row.date === undefined ||
      row.summary === undefined ||
      row?.voucher_number === undefined
  );
  const downloadTemplate = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([HEAD.map((header) => header.key)]);
    const wsCols = HEAD.map((_) => ({ wch: 15 }));
    ws["!cols"] = wsCols;
    XLSX.utils.book_append_sheet(wb, ws, "Template");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "template.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const staticRanges = [
    ...defaultStaticRanges,
    ...createStaticRanges([
      {
        key: "lastTenTrans",
        label: "Last 10 Transactions",
        range: () => ({
          startDate: startOfYear("00-00-0000"),
          endDate: startOfYear("00-00-0000"),
        }),
      },
      {
        key: "thisYear",
        label: "This Financial Year",
        range: () => ({
          startDate: startOfYear(new Date()),
          endDate: endOfYear(new Date()),
        }),
      },
      {
        key: "pastYear",
        label: "Past Financial Year",
        range: () => ({
          startDate: startOfYear(subYears(new Date(), 1)),
          endDate: endOfYear(subYears(new Date(), 1)),
        }),
      },
    ]),
  ].splice(2, 12);

  const downloadImage = async (imageUrl, filename) => {
    axios
      .get(imageUrl, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "image.jpg");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  return (
    <>
      <Head title="Cashbook">
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Click to delete entry</Tooltip>}
        >
          <Button
            size="sm"
            variant="danger"
            className="me-2"
            style={
              checkedCheckBoxes.length
                ? { display: "block" }
                : { display: "none" }
            }
            id="multipleDeleteBtn"
            onClick={() => confirmBeforeDeletion("", 1)}
          >
            {checkedCheckBoxes.length > 1 ? "Delete Entries" : "Delete Entry"}
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Click to add cash in</Tooltip>}
        >
          <Button
            size="sm"
            variant="success"
            className="px-4 me-2"
            onClick={() => {
              setValue("date", moment().format("YYYY-MM-DD"));
              setValue("transaction_type", "1");
              setValue("bill_image", null);
              setValue("voucher_number", null);

              setModalActions({
                ...modalActions,
                showModal: true,
                title: "In Entry",
                submitButtonTitle: "Add Cash",
              });
            }}
          >
            Cash In
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Click to add cash out</Tooltip>}
        >
          <Button
            size="sm"
            variant="danger"
            className="px-3"
            onClick={() => {
              setValue("date", moment().format("YYYY-MM-DD"));
              setValue("transaction_type", "0");
              setValue("bill_image", null);
              setValue("voucher_number", null);

              setModalActions({
                ...modalActions,
                showModal: true,
                title: "Out Entry",
                submitButtonTitle: "Withdraw Cash",
              });
            }}
          >
            Cash Out
          </Button>
        </OverlayTrigger>
      </Head>
      <input
        type="file"
        id="fileInput"
        accept="image/png"
        style={{ display: "none" }}
        onChange={(event) => handleFileChange(event, true)}
      />
      {/* <FileImporter /> */}
      <Row className="m-0 g-2">
        <Col xs={12}>
          <Card className="border-0 shadow-sm">
            <Card.Header className="row d-flex justify-content-around align-items-center g-0">
              <Col>
                Filter
                {/* <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      Click to add new entry
                    </Tooltip>
                  }
                >
                  <Button size="sm" onClick={() => setAddEntry(true)}>
                    Add New Entry
                  </Button>
                </OverlayTrigger> */}
              </Col>{" "}
              <Col xs="auto" className=" position-relative">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      Click to select the date range
                    </Tooltip>
                  }
                >
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle
                      variant="secondary"
                      id="dropdown-basic"
                      size="sm"
                    >
                      {additionalFilter?.lastTenTransaction
                        ? "Last 10 transactions"
                        : `${moment(dateRange[0].startDate).format(
                            "DD MMM, YYYY"
                          )} - ${moment(dateRange[0].endDate).format(
                            "DD MMM, YYYY"
                          )}`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="p-0 overflow-hidden">
                      <Dropdown.Item className="p-0 ">
                        <DateRangePicker
                          ranges={dateRange}
                          onChange={handleSelect}
                          editableDateInputs={true}
                          staticRanges={staticRanges}
                          maxDate={new Date()}
                        />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </OverlayTrigger>
              </Col>
            </Card.Header>
            <Card.Body>
              <DataTables
                url={`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_FETCH_CASHBOOK_ENTRIES}`}
                columns={columns}
                defaultSortedColumnIndex={2}
                confirmBeforeDeletion={confirmBeforeDeletion}
                handleAddVoucher={handleAddVoucher}
                handleViewVoucher={handleViewVoucher}
                setSelectedItems={setSelectedItems}
                handleViewBill={handleViewBill}
                handleAddBill={handleAddBill}
                dispatchCallType={FETCH_CASHBOOK_ENTRIES_SUCCESS}
                additionalFilter={additionalFilter}
                viewCashbookEntry={viewCashbookEntry}
                updateCashbookEntry={updateCashbookEntry}
                handleImportModal={handleImportModal}
                handleCustomExport={handleCustomExport}
                // addEntry={addEntry}
                // cancelNewEntriesColumn={cancelNewEntriesColumn}
                // onSubmit={onSubmit}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer style={{ top: "60px" }} />
      <Modal show={modalActions.showModal}>
        <Modal.Header className="border-0 m-auto">
          <h6 className="m-0">{modalActions.title}</h6>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label htmlFor="date" className="mb-0">
              Date
            </Form.Label>
            <InputGroup className="mb-3">
              <DatePicker
                required
                name="date"
                maxDate={new Date()}
                id="date"
                dateFormat={
                  formatDate === null || formatDate === undefined
                    ? "dd MMM, yyyy"
                    : formatDate?.replace(/D/g, "d")?.replace(/Y/g, "y")
                }
                selected={
                  watch("date") !== undefined
                    ? new Date(watch("date"))
                    : new Date()
                }
                onChange={(e) =>
                  setValue("date", moment(e).format("YYYY-MM-DD"))
                }
                customInput={<Form.Control aria-describedby="basic-addon1" />}
              />
              <Form.Control.Feedback
                type={`${errors.date?.message && "invalid"}`}
              >
                {errors.date?.message}
              </Form.Control.Feedback>
            </InputGroup>
            <Form.Label htmlFor="amount" className="mb-0">
              Amount
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                onKeyPress={numberonly}
                id="amount"
                {...register("amount")}
                style={{ borderRadius: 8 }}
              />
              <Form.Control.Feedback
                type={`${errors.amount?.message && "invalid"}`}
              >
                {errors.amount?.message}
              </Form.Control.Feedback>
            </InputGroup>
            <Form.Label htmlFor="summary" className="mb-0">
              Description
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                as="textarea"
                id="summary"
                {...register("summary")}
                style={{ borderRadius: 8 }}
              />
              <Form.Control.Feedback
                type={`${errors.summary?.message && "invalid"}`}
              >
                {errors.summary?.message}
              </Form.Control.Feedback>
            </InputGroup>

            {modalActions.title === "Out Entry" ||
            getValues("transaction_type") === 0 ? (
              <>
                <Form.Label htmlFor="bill_image" className="mb-0">
                  Attach Bill
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    ref={fileInputRef}
                    type="file"
                    {...register("attachment")}
                    onChange={handleFileChange}
                  />
                </InputGroup>
              </>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            size="sm"
            variant="light"
            onClick={() => {
              reset();
              setModalActions({
                ...modalActions,
                showModal: false,
                title: "",
              });
            }}
          >
            Cancel
          </Button>
          {modalActions.submitButtonTitle && (
            <Button
              size="sm"
              variant="success"
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitting}
            >
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={deleteCashbookEntriesAction.showConfirmationModal} centered>
        <Modal.Header className="border-0 justify-content-center text-danger fw-bold fs-6">
          Warning!
        </Modal.Header>
        <Modal.Body
          className="text-center py-4"
          style={{
            color: "black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Are you sure you want to delete selected{" "}
          {checkedCheckBoxes.length > 1 ? "entries" : "entry"}?
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            size="sm"
            variant="secondary"
            onClick={() =>
              setDeleteCashbookEntriesAction({
                ...deleteCashbookEntriesAction,
                showConfirmationModal: false,
                idsToDelete: [],
              })
            }
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => deleteCashbookEntries()}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={addVoucher} size="xl">
        <Modal.Body>
          <AllVouchers
            isCashBook
            callBackHandleChange={callBackHandleChange}
            cashBookAmount={getValues("amount")}
            cashBookDate={getValues("date")}
            VoucherNumberNotLinked={getValues("voucher_number") || false}
          />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            size="sm"
            variant="light"
            onClick={() => {
              reset();
              setAddVoucher(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={viewVoucher}
        size="lg"
        center
        onHide={() => setViewVoucher(false)}
      >
        {" "}
        <Modal.Header closeButton className="border-0">
          <Modal.Title style={{ fontSize: "18px" }}>Voucher</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-2">
          <SelectVouchers
            theme={theme.VoucherTemplate}
            id={viewVoucher || viewVoucherId}
            requestType="view"
            isCashBook
            handleUnlinkVoucher={handleUnlinkVoucher}
          />
        </Modal.Body>
      </Modal>
      <Modal show={misMatchAmount} centered>
        <Modal.Header className="border-0 justify-content-center fw-bold fs-6">
          Notice!
        </Modal.Header>
        <Modal.Body className="text-center py-2">
          {`The voucher amount does not match the cashbook amount. The voucher
          amount is ₹${misMatchAmount}, while the cashbook amount ₹${getValues(
            "amount"
          )}.`}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            size="sm"
            variant="light"
            onClick={() => {
              setMisMatchAmount(false);
              reset();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={() => UpdateVoucherId(getValues())}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={impModal}
        size={importedData?.length > 0 ? "xl" : "lg"}
        centered
        id="modal-root"
      >
        <ModalHeader
          className=" border-0 fw-bold fs-4 text-dark"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {" "}
          {importedData?.length > 0
            ? "Imported Cashbook Data"
            : "Import Excel file"}
          {importedData?.length === 0 && (
            <Button
              variant="light"
              size="sm"
              className="text-primary"
              onClick={downloadTemplate}
            >
              Download Sample File
            </Button>
          )}
        </ModalHeader>
        <Modal.Body>
          {importedData?.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Voucher Id</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {importedData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>
                      {moment(row?.date).format(
                        formatDate === undefined || formatDate === null
                          ? "DD MMM, YYYY"
                          : formatDate
                      )}
                    </td>
                    <td>{row?.summary}</td>
                    <td>{row?.voucher_number}</td>
                    <td>
                      <div>
                        {row?.amount} &nbsp;
                        {row?.transaction_type === "1" ? (
                          <span style={{ color: "rgb(0, 142, 0) " }}>Cr</span>
                        ) : (
                          <span style={{ color: "red" }}>Dr</span>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <FileImporter setImportedData={setImportedData} />
          )}
          {isCorrectFormat.length > 0 && (
            <Alert variant="warning position-sticky bottom-0">
              Format are not correct.
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            size="sm"
            variant="light"
            onClick={() => {
              setImportedData([]);
              setImpModal(false);
            }}
          >
            Cancel
          </Button>
          {importedData?.length > 0 && isCorrectFormat.length === 0 && (
            <Button
              size="sm"
              variant="primary"
              onClick={() => {
                CSVimport();
              }}
            >
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        center
        show={viewBill !== false}
        onHide={() => setViewBill(false)}
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title style={{ fontSize: "18px" }}>Bill Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={`${process.env.REACT_APP_API_BASE_URL_IMAGE}media/${viewBill}`}
            alt="img"
            width="100%"
            height="100%"
          ></img>
          {/* <div className="w-100 d-flex justify-content-end mt-3 position-sticky bottom-0"> */}
          <Row className="position-sticky bg-white bottom-0 p-2 m-0 mt-3">
            <Col className="centerBtn">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip">Click to unlink Bill</Tooltip>
                }
              >
                <Button
                  style={{ width: "100%" }}
                  variant="primary"
                  onClick={() => {
                    setUnlink({ ...unlink, isOpen: true });
                    setViewBill(false);
                  }}
                >
                  Unlink
                </Button>
              </OverlayTrigger>
            </Col>
            <Col className="centerBtn">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip">Click to download Bill</Tooltip>
                }
              >
                <Button
                  variant="secondary"
                  className="me-3"
                  style={{ width: "100%" }}
                  onClick={() =>
                    downloadImage(
                      `${process.env.REACT_APP_API_BASE_URL_IMAGE}media/${viewBill}`,
                      viewBill
                    )
                  }
                >
                  Download
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
          {/* </div> */}
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={unlink?.isOpen}
        onHide={() => setUnlink({ ...unlink, isOpen: false })}
      >
        <Modal.Header className="border-0 justify-content-center fw-bold fs-6">
          Alert!
        </Modal.Header>

        <Modal.Body className="text-center fs-6">{unlink?.msg}</Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            size="sm"
            variant="secondary"
            className="mx-2"
            onClick={() => {
              setUnlink({ ...unlink, isOpen: false, id: "", msg: "" });
            }}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            disabled={unlink?.isLoader}
            onClick={() => {
              if (unlink?.msg?.includes("unlink")) {
                setUnlink({ ...unlink, isOpen: false, isLoader: true });
                handleUnlink(unlink?.id, unlink?.type);
              } else if (unlink?.type === "bill") {
                handleAddBill(unlink?.id);
                setUnlink({ ...unlink, isOpen: false, msg: "" });
              } else {
                handleAddVoucher(unlink?.id);
                setUnlink({ ...unlink, isOpen: false, msg: "" });
              }
            }}
          >
            {unlink?.isLoader ? "Proceeding..." : "Proceed"}
          </Button>
        </Modal.Footer>
      </Modal>

      <CashbookEntries
        setAddEntry={setAddEntry}
        addEntry={addEntry}
        createOrUpdateCashbookEntry={createOrUpdateCashbookEntry}
        numberonly={numberonly}
      />

      <CustomExport isOpen={isCustomExport} onClose={handleCustomExportClose} />
      <Modals showModal={showPopup} handleClose={handlePopupClose} />
    </>
  );
}
