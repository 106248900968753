import React, { useState, useEffect } from "react";
import { Button, Modal, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../Invoice/Template1.css";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import * as yup from "yup";
import { useFormik } from "formik";
import VoucherTemplate1 from "./VoucherTemplate1";
import { fetchSuggestions } from "../../Utils/AutoComplete";
import { generateInvoiceNumber } from "../../Utils/generateInvoiceNumber";
import VoucherTemplate2 from "./VoucherTemplate2";
import VoucherTemplate3 from "./VoucherTemplate3";

function SelectVouchers(props) {
  const { state } = useLocation();
  const {
    theme,
    setIsShow = false,
    getVoucherData = false,
    isShow = false,
  } = props;
  const { userInfo } = useSelector((state) => state?.userInfo);
  const { vouchers } = useSelector((state) => state?.vouchers);
  const navigate = useNavigate();
  const [autoComplete, setAutoComplete] = useState([]);
  const isShowTemplate = window.location.pathname.includes("allTemplate");
  const [capitalizedPaidTo, setCapitalizedPaidTo] = useState(true);
  const { id: pathId, requestType: pathRequestType } = useParams();
  const id = props?.id || pathId;
  const requestType = props.requestType || pathRequestType;
  const isCreateVoucher =
    window.location.pathname.includes("create-new-voucher") ||
    requestType === "create-duplicate-voucher";
  const [applyGST, setApplyGST] = useState(false);
  const [applyDIS, setApplyDIS] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [, setValueUpdater] = useState(null);
  const [showModal, setShowModal] = useState({ is: false, msg: "" });

  useEffect(() => {
    if (vouchers?.length === 0 && getVoucherData !== false) getVoucherData();
  }, [vouchers]);

  const validationSchema = yup.object().shape({
    paid_to: yup.string().required("Required field."),
    address: yup.string().required("Required field."),
    payment_mode: yup.string().required("Required field."),
    bill_description: yup.array().of(
      yup.object().shape({
        item_description: yup.string().required("Required field."),
        item_price: yup.string().required("Required field."),
        quantity: yup.string().required("Required field."),
      })
    ),
    payment_mode_info: yup.string().when("payment_mode", {
      is: "Cash",
      then: yup.string().nullable(true),
      otherwise: yup.string().when("payment_mode", {
        is: "Card",
        then: yup
          .string()
          .matches(/^\d{4}$/, "Must be exactly 4 digits")
          .required("Last 4 digits are required."),
        otherwise: yup.string().required("Required field."),
      }),
    }),
  });

  const initialValues =
    state?.isTemplateChange || isShow?.values?.template_id
      ? state?.values || isShow?.values
      : (isCreateVoucher && requestType !== "create-duplicate-voucher") ||
        isShowTemplate
      ? {
          has_gst: 0,
          applyGST: false,
          has_discounts: 0,
          template_id: theme,
          payment_mode: "",
          payment_mode_info: "",
          voucher_no: state?.voucherNumber ? state?.voucherNumber : "",
          paid_to: "",
          address: "",
          user_id: userInfo?.user_data?.id,
          total_amount: "",
          note: "",
          date: moment().format("YYYY-MM-DD"),
          bill_description: [
            {
              item_description: "",
              item_price: "",
              quantity: 1,
            },
          ],
        }
      : {
          ...vouchers?.find((item) => item.id === parseInt(id, 10)),
          voucher_no: vouchers?.find((item) => item.id === parseInt(id, 10))
            ?.invoice_no,
          removedItems: [],
        };
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      if (isCreateVoucher) {
        const allValues = {
          ...formValues,
          invoice_no: generateInvoiceNumber(formValues?.date),
          voucher_no: undefined,
        };
        handleCreateVoucher(allValues);
      } else if (requestType === "edit") {
        const allValues = {
          ...formValues,
          invoice_no: formValues?.voucher_no,
          voucher_no: undefined,
        };
        handleUpdateVoucher(allValues);
      }
    },
  });
  const { ToWords } = require("to-words");
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const handlePaidToChange = (e) => {
    const inputValue = e.target.value;
    const capitalizedValue =
      capitalizedPaidTo && inputValue !== ""
        ? inputValue.charAt(0).toUpperCase() + inputValue.slice(1)
        : inputValue;

    formik.handleChange(e);
    formik.setFieldValue("paid_to", capitalizedValue);

    if (capitalizedPaidTo) {
      setCapitalizedPaidTo(false);
    }
  };

  const addFields = () => {
    let newfield = {
      item_description: "",
      item_price: "",
      quantity: 1,
    };
    if (!isCreateVoucher) {
      newfield = { ...newfield, id: null };
    }
    formik.setFieldValue("bill_description", [
      ...formik.values.bill_description,
      newfield,
    ]);
    setValueUpdater(Math.random());
  };

  const removeFields = (index, itemId) => {
    if (requestType === "edit") {
      formik?.setFieldValue("removedItems", [
        ...formik?.values?.removedItems,
        itemId,
      ]);
    }
    if (
      formik.values.bill_description &&
      Array.isArray(formik.values.bill_description)
    ) {
      formik.values.bill_description.splice(index, 1);
    }
    setValueUpdater(Math.random());
  };

  const handleClose = () => {
    if (showModal?.navigate) {
      navigate(showModal?.navigate);
    }
    setShowModal({
      ...showModal,
      is: false,
      msg: "",
    });
  };

  const handleCreateVoucher = (formValues) => {
    setDisableSubmit(true);
    formValues["invoice_date"] = moment(formValues?.date).format("DD/MM/YYYY");
    formValues["has_gst"] = applyGST ? formValues?.has_gst : 0;
    formValues["has_discounts"] = applyDIS ? formValues?.has_discounts : 0;
    formValues["bill_description"] =
      formValues?.bill_description?.length > 0
        ? formValues?.bill_description?.map((row) => ({
            item_description: row?.item_description,
            item_price: row?.item_price,
            quantity: row?.quantity,
          }))
        : [];

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_CREATE_NEW_VOUCHER}`,
        formValues,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        if (res?.status === 200) {
          if (formValues?.payment_mode === "Cash") {
            createCashbookEntry(formValues, res.data?.invoice_no);
          } else {
            toast.success("Voucher created successfully", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            setTimeout(() => {
              setDisableSubmit(false);
              state?.voucherNumber
                ? navigate("/cashbook", {
                    state: { isCashBook: formValues?.voucher_no },
                  })
                : navigate("/vouchers");
            }, 4000);
          }
        }
      })
      .catch((error) => {
        setDisableSubmit(false);
        setShowModal({
          ...showModal,
          is: true,
          msg:
            error?.data?.message ||
            error?.response?.data?.message ||
            error?.message,
        });

        return error;
      });
  };
  const handleUpdateVoucher = (formValues) => {
    setDisableSubmit(true);
    formValues["invoice_date"] = moment(formValues?.date).format("DD/MM/YYYY");
    formValues["has_gst"] = applyGST ? formValues?.has_gst : 0;
    formValues["has_discounts"] = applyDIS ? formValues?.has_discounts : 0;

    formValues["template_id"] =
      formValues?.template_id === undefined || formValues?.template_id === null
        ? 1
        : formValues?.template_id;
    if (formValues?.removedItems?.length === 0) {
      delete formValues?.removedItems;
    }

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_UPDATE_VOUCHER}`,
        formValues,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Voucher updated successfully", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            getVoucherData();
            setDisableSubmit(false);
            if (setIsShow !== false) {
              setIsShow({
                open: false,
                id: "",
                requestType: "",
              });
            }
            // navigate("/vouchers");
          }, 4000);
        }
      })
      .catch((error) => {
        setDisableSubmit(false);
        setShowModal({
          ...showModal,
          is: true,
          msg:
            error?.data?.message ||
            error?.response?.data?.message ||
            error?.message,
        });

        return error;
      });
  };
  const createCashbookEntry = async (formData, invoice_no = false) => {
    const payload = {
      cashbook_entry: [
        {
          amount: formData?.total_amount,
          summary: formData?.paid_to,
          date: formData?.date,
          transaction_type: "0",
          bill_image: null,
          voucher_number: invoice_no || null,
          created_by: userInfo?.user_data?.id,
        },
      ],
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ADD_CASHBOOK}`,
        payload,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          toast.success("Voucher created successfully", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            setDisableSubmit(false);
            navigate("/vouchers");
          }, 4000);
        }
      })
      .catch((error) => {
        setDisableSubmit(false);
        setShowModal({
          ...showModal,
          is: true,
          navigate: `/vouchers`,
          msg: [
            `Voucher created successfully`,
            <br />,
            <br />,
            error?.data?.message ||
              error?.response?.data?.message ||
              error?.message,
          ],
        });

        return error;
      });
  };
  const handleAutoComplete = (e, searching_for, requestType) => {
    const query = e.target.value;
    if (query !== "") {
      fetchSuggestions(query, searching_for, requestType)
        .then((res) => {
          if (res?.status === 200) {
            setAutoComplete(res?.data?.data);
          }
        })
        .catch((error) => {
          setAutoComplete([]);
        });
    } else {
      setAutoComplete([]);
    }
  };
  const renderTooltip = (searching_for) => (
    <Tooltip id="tooltip" style={{ zIndex: 9999 }}>
      <div style={{ maxHeight: "200px", overflowY: "auto" }}>
        <ul
          style={{ listStyleType: "none", textAlign: "left", paddingLeft: 0 }}
        >
          {autoComplete.map((suggestion, index) => (
            <li
              key={index}
              className="my-2"
              style={{
                cursor: "pointer",
                fontWeight: "900px",
                fontSize: "13px",
                margin: "5px",
                transition: "color 0.3s ease",
              }}
              onClick={() => {
                formik?.setFieldValue(`${searching_for}`, suggestion?.value);
                setAutoComplete([]);
              }}
              onMouseEnter={(e) => (e.target.style.color = "#fd5631")}
              onMouseLeave={(e) => (e.target.style.color = "")}
            >
              {suggestion?.value}
            </li>
          ))}
        </ul>
      </div>
    </Tooltip>
  );
  const handleUnlink = () => {
    const { handleUnlinkVoucher } = props;
    handleUnlinkVoucher();
  };
  const handleTemplatesChange = (
    needTemplateChange = false,
    tempId = false
  ) => {
    if (needTemplateChange === false && tempId && setIsShow) {
      setIsShow({
        ...isShow,
        needTemplateChange,
        values: undefined,
      });
      formik?.setFieldValue("template_id", tempId);
    }
    if (isShow && setIsShow) {
      setIsShow({
        ...isShow,
        needTemplateChange,
        values: formik?.values,
      });
    }
  };

  const getTemplateView = (id) => {
    let temp;
    switch (id) {
      case 3:
        temp = (
          <VoucherTemplate3
            requestType={requestType}
            isCreateVoucher={isCreateVoucher}
            toWords={toWords}
            addFields={addFields}
            disableSubmit={disableSubmit}
            removeFields={removeFields}
            formik={formik}
            applyGST={applyGST}
            applyDIS={applyDIS}
            setApplyGST={setApplyGST}
            setApplyDIS={setApplyDIS}
            showTemplate={isShowTemplate}
            handlePaidToChange={handlePaidToChange}
            handleAutoComplete={handleAutoComplete}
            autoComplete={autoComplete}
            renderTooltip={renderTooltip}
            fromCashBook={props?.isCashBook || false}
            handleUnlinkVoucher={handleUnlink}
            handleTemplatesChange={handleTemplatesChange}
          />
        );
        break;

      case 5:
        temp = (
          <VoucherTemplate1
            requestType={requestType}
            isCreateVoucher={isCreateVoucher}
            toWords={toWords}
            addFields={addFields}
            disableSubmit={disableSubmit}
            removeFields={removeFields}
            formik={formik}
            applyGST={applyGST}
            applyDIS={applyDIS}
            setApplyGST={setApplyGST}
            setApplyDIS={setApplyDIS}
            showTemplate={isShowTemplate}
            handlePaidToChange={handlePaidToChange}
            handleAutoComplete={handleAutoComplete}
            autoComplete={autoComplete}
            renderTooltip={renderTooltip}
            fromCashBook={props?.isCashBook || false}
            handleUnlinkVoucher={handleUnlink}
            handleTemplatesChange={handleTemplatesChange}
          />
        );
        break;

      default:
        temp = (
          <VoucherTemplate2
            requestType={requestType}
            isCreateVoucher={isCreateVoucher}
            toWords={toWords}
            addFields={addFields}
            disableSubmit={disableSubmit}
            removeFields={removeFields}
            formik={formik}
            applyGST={applyGST}
            applyDIS={applyDIS}
            setApplyGST={setApplyGST}
            setApplyDIS={setApplyDIS}
            showTemplate={isShowTemplate}
            handlePaidToChange={handlePaidToChange}
            handleAutoComplete={handleAutoComplete}
            autoComplete={autoComplete}
            renderTooltip={renderTooltip}
            fromCashBook={props?.isCashBook || false}
            handleUnlinkVoucher={handleUnlink}
            handleTemplatesChange={handleTemplatesChange}
          />
        );
    }

    return temp;
  };
  return (
    <>
      {getTemplateView(parseInt(formik?.values?.template_id, 10))}

      <Modal show={showModal?.is} onHide={handleClose} centered>
        <Modal.Header className="d-flex justify-content-center border-0">
          <Modal.Title style={{ color: "black" }}>Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            color: "black",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {showModal?.msg}{" "}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            className="closeButton"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SelectVouchers;
