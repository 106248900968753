import React, { useState } from "react";
import { Col } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import * as XLSX from "xlsx";
import Tooltip from "react-bootstrap/Tooltip";
import { useSelector } from "react-redux";
import moment from "moment";

const HEAD = [
  { id: "date", key: "Date" },
  { id: "description", key: "Description" },
  { id: "voucher_Id", key: "Voucher No" },
  { id: "credit_Amount", key: "Credit Amount" },
  { id: "debit_Amount", key: "Debit Amount" },
  { id: "balance", key: "Balance" },
];

const FileImporter = ({ setImportedData }) => {
  const [file, setFile] = useState(null);
  const { userInfo } = useSelector((state) => state?.userInfo);

  const formatDate = (dateObj) => {
    if (dateObj instanceof Date) {
      return dateObj.toISOString().slice(0, 10);
    } else {
      console.error("Invalid date object:", dateObj);
      return null; // or throw an error
    }
  };
  const convertSerialToDate = (serialNumber) => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const excelStartDate = new Date("1899-12-30"); // Excel start date
    const offsetInDays = serialNumber - 1; // Offset from Excel start date
    const offsetInMilliseconds = offsetInDays * millisecondsPerDay;
    const date = new Date(excelStartDate.getTime() + offsetInMilliseconds);
    return formatDate(date); // Convert to "YYYY-MM-DD" format
  };
  const handleFile = async (file) => {
    const reader = new FileReader();

    const readFileAsync = (file) =>
      new Promise((resolve) => {
        reader.onload = (e) => resolve(e);
        reader.readAsArrayBuffer(file);
      });

    try {
      const e = await readFileAsync(file);
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        raw: true,
        cellDates: true,
      });
      if (excelData.length > 0) {
        const headers = excelData[0];
        const formattedData = excelData.slice(1).map((row) => {
          const rowData = {};

          headers.forEach((header, index) => {
            const matchedHead = HEAD.find((head) =>
              head.key.toLowerCase()?.includes(header.toLowerCase())
            );
            if (matchedHead) {
              rowData[matchedHead.id] = row[index];
            }
          });
          if (typeof rowData.date === "number") {
            rowData.date = convertSerialToDate(rowData.date);
          } else if (rowData.date?.includes("/")) {
            rowData.date = moment(rowData?.date, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            );
          }
          return {
            date: moment(rowData?.date).format("YYYY-MM-DD"),
            summary: rowData?.description,
            voucher_number: `${rowData?.voucher_Id}`,
            amount: `${rowData?.credit_Amount || rowData?.debit_Amount}`,
            transaction_type: rowData?.credit_Amount ? "1" : "0",
            created_by: userInfo?.user_data?.id,
            bill_image: null,
          };
        });
        setImportedData(formattedData);
      } else {
        console.log("Excel file is empty.");
      }
    } catch (error) {
      console.error("Error reading the file:", error);
    }
  };

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      handleFile(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setFile(file);
      handleFile(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <Col md={12}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <label htmlFor="input-file" id="drop-area">
            <input
              type="file"
              accept=".xlsx, .xls"
              id="input-file"
              onChange={handleInputChange}
              hidden
            />
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="button-tooltip">Upload Excel File</Tooltip>}
            >
              <div
                style={{
                  marginTop: "20px",
                  width: "100%",
                  height: "100%",
                  borderRadius: "20px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  cursor: "pointer",
                }}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <img
                  style={{
                    backgroundColor: "#F7F7F8",
                    width: "50%",
                    borderRadius: "20px",
                  }}
                  className={file ? "" : "p-3"}
                  src={
                    file
                      ? file
                      : "https://cdn-icons-png.flaticon.com/256/20/20829.png"
                  }
                  alt="logoImg"
                  width={file ? 150 : 100}
                  height={file ? 150 : 100}
                />
                <p className="mt-1 mb-0">
                  {file ? "" : "Drag and drop or Click here to upload file"}
                </p>
              </div>
            </OverlayTrigger>
          </label>
        </div>
      </Col>
    </div>
  );
};

export default FileImporter;
