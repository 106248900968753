import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import axios from "axios";

const AutoComplete = ({
  wrapperClassName = false,
  requestType,
  searchingFor,
  callbackFieldValue,
  fieldValue,
  ...props
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  const handleAutoComplete = async (query) => {
    return await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_AUTO_COMPLETE}`,
        {
          requestType,
          searchingFor,
          query,
        }
      )
      .then((res) => {
        setSuggestions(res.data.data);
      })
      .catch((error) => {
        return error;
      });
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    // Delay setting isFocused to false to allow time for click event to be processed
    setTimeout(() => {
      setIsFocused(false);
    }, 100);
  };
  useEffect(() => {
    if (fieldValue && suggestions.length) {
      setIsFocused(true); // Keep focused if there are suggestions
    }
  }, [suggestions]);

  return (
    <>
      <Form.Group
        className={
          wrapperClassName
            ? `position-relative ${wrapperClassName}`
            : "position-relative"
        }
      >
        <Form.Control
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={(e) => {
            callbackFieldValue(e);
            handleAutoComplete(e.target.value);
          }}
          value={fieldValue}
          {...props}
        />
        {isFocused && fieldValue && suggestions.length ? (
          <ListGroup
            className="position-absolute w-100"
            style={{ maxHeight: "200px", overflowY: "auto", zIndex: "9999" }}
          >
            {suggestions.map((item) => (
              <ListGroup.Item
                style={{ cursor: "pointer" }}
                onClick={() => {
                  callbackFieldValue({ target: { value: item.value } });
                  handleFocus();
                  setSuggestions([]);
                }}
              >
                {item.value}
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : null}
      </Form.Group>
    </>
  );
};

export default AutoComplete;
