import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import axios from "axios";
import Modals from "../components/Modal";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Container, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function ChangePassword() {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.userInfo);
  const user = Object.keys(userInfo).length && userInfo?.user_data;
  const [checkVal, setCheckVal] = useState();
  const [oldpass, setOldpass] = useState("");
  const [newpass, setnewpass] = useState("");
  const [showPopup, setShowPopup] = useState({ is: false, msg: "" });
  const [confirmpass, setconfirmpass] = useState("");

  const handlePopupClose = () => {
    setShowPopup({
      ...showPopup,
      is: false,
      msg: "",
    });
  };

  const Submit = async (e) => {
    e.preventDefault();
    if (newpass !== confirmpass) {
      alert("please enter correct confirm password");
    } else {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_UPDATE_PROFILE_PASSWORD}`,
        {
          user_id: user,
          old_pass: oldpass,
          new_pass: newpass,
        }
      );

      if (result.status === 200) {
        toast.success("Password Changed successfully", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setCheckVal(true);
        setTimeout(() => {
          navigate("/profile");
        }, 4000);
      } else {
        // toast.error("Old password not matched", {
        //   position: "top-right",
        //   autoClose: 4000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
        setShowPopup({
          ...showPopup,
          is: true,
          msg: "Old password not matched",
        });
      }
    }
  };

  return (
    <>
      <Container>
        <Form.Text
          className=" d-flex justify-content-center mt-3 text-dark"
          style={{ fontWeight: "600", fontSize: "25px" }}
        >
          Change Password
        </Form.Text>
        <div className="d-flex justify-content-center ">
          <Card
            className=" p-4 pb-0 border-0 bg-light"
            style={{
              width: "500px",
              minHeight: "400px",
              marginTop: "35px",
              boxShadow:
                "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px",
            }}
          >
            <Form onSubmit={Submit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Old password
                </Form.Label>
                <Form.Control
                  minLength={5}
                  required
                  name="oldpass"
                  type="password"
                  placeholder="Enter old password"
                  value={oldpass}
                  onChange={(e) => setOldpass(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  New password
                </Form.Label>
                <Form.Control
                  minLength={5}
                  required
                  name="newpass"
                  type="password"
                  placeholder="Enter new password"
                  value={newpass}
                  onChange={(e) => setnewpass(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Confirm password
                </Form.Label>
                <Form.Control
                  minLength={5}
                  required
                  name="confirmpass"
                  type="password"
                  placeholder="Enter confirm password"
                  value={confirmpass}
                  onChange={(e) => setconfirmpass(e.target.value)}
                />
              </Form.Group>

              <div className="d-flex justify-content-end mt-5 ">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="button-tooltip">Click to back profile</Tooltip>
                  }
                >
                  <Button
                    variant="secondary"
                    className="btn-sm me-3"
                    onClick={() => navigate("/profile")}
                  >
                    Back
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="button-tooltip">Click to Submit</Tooltip>
                  }
                >
                  <Button
                    type="submit"
                    disabled={checkVal}
                    variant="primary"
                    className="btn-sm"
                  >
                    Submit
                  </Button>
                </OverlayTrigger>
              </div>

              <ToastContainer />
              <Modals showModal={showPopup} handleClose={handlePopupClose} />
            </Form>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default ChangePassword;
