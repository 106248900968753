import {
  SET_DEFAULT_INVOICE_TEMPLATE,
  SET_DEFAULT_VOUCHER_TEMPLATE,
  SET_REFRESH_DATA_TABLE,
} from "./ThemeActionTypes";

const initialState = {
  InvoiceTemplate: 1,
  VoucherTemplate: 1,
  refreshDataTable: false,
};

export const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFAULT_INVOICE_TEMPLATE:
      return { ...state, InvoiceTemplate: action.data };
    case SET_DEFAULT_VOUCHER_TEMPLATE:
      return { ...state, VoucherTemplate: action.data };
    case SET_REFRESH_DATA_TABLE:
      return { ...state, refreshDataTable: action.data };

    default:
      return state;
  }
};
