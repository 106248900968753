import React, { useEffect, useRef } from "react";
import "../App.css";
import Table from "react-bootstrap/Table";
import "datatables.net-buttons-bs5";
import "datatables.net-buttons/js/buttons.colVis.mjs";
import "datatables.net-buttons/js/buttons.html5.mjs";
import "datatables.net-buttons/js/buttons.print.mjs";
import DataTable from "datatables.net-bs5";
import { dataTableConfig } from "../Utils/DataTableConfig";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

var checkbox_data_array = [];

const DataTables = (props) => {
  const dispatch = useDispatch();

  const {
    url,
    columns,
    defaultSortedColumnIndex,
    requestMethod,
    dispatchCallType,
    setSelectedItems = false,
    additionalFilter,
    handleImportModal,
    handleCustomExport,
    noOrder = false,
  } = props;
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const configureDataTable = dataTableConfig(
    url,
    requestMethod || "GET",
    columns,
    defaultSortedColumnIndex || 0,
    "",
    dispatch,
    dispatchCallType,
    additionalFilter,
    handleImportModal,
    handleCustomExport,
    noOrder ? false : true
  );

  const handleCheckBox = (component, isAll = false, selectedItemIds) => {
    if (isAll) {
      const checkBoxes = document.querySelectorAll(".checkBox");
      const isChecked = component.checked;

      checkBoxes.forEach((checkbox) => {
        checkbox.checked = isChecked;
      });
    }

    const checkedCheckBoxes = document.querySelectorAll(".checkBox:checked");
    const multipleDeleteBtn = document.getElementById("multipleDeleteBtn");
    const duplicateInvoice = document.getElementById("duplicateInvoice");
    const duplicateVoucher = document.getElementById("duplicateVoucher");

    const checkAll = document.querySelector(".checkAll");
    const checkBoxes = document.querySelectorAll(".checkBox");
    if (duplicateInvoice) {
      if (checkedCheckBoxes.length === 1) {
        duplicateInvoice.style.display = "block";
      } else {
        duplicateInvoice.style.display = "none";
      }
    }

    if (duplicateVoucher) {
      if (checkedCheckBoxes.length === 1) {
        duplicateVoucher.style.display = "block";
      } else {
        duplicateVoucher.style.display = "none";
      }
    }

    if (multipleDeleteBtn) {
      if (checkedCheckBoxes.length > 0) {
        multipleDeleteBtn.style.display = "block";
      } else {
        multipleDeleteBtn.style.display = "none";
      }
    }

    if (checkedCheckBoxes.length === checkBoxes.length) {
      checkAll.checked = true;
    } else {
      checkAll.checked = false;
    }

    checkbox_data_array = Array.from(checkedCheckBoxes).map((checkbox) => {
      return checkbox?.value;
    });
    if (setSelectedItems !== false) {
      setSelectedItems(checkbox_data_array);
    }

    return checkbox_data_array;
  };

  const handleDataValueClick = (event) => {
    const target =
      event.target.getAttribute("data-action") === null
        ? event?.target?.parentElement?.closest("[data-action]")
        : event.target;
    const dataAction = target.getAttribute("data-action");
    if (dataAction)
      switch (dataAction) {
        case "view":
          navigate(`view/${target.getAttribute("data-id")}`);
          break;

        case "edit":
          navigate(`edit/${target.getAttribute("data-id")}`);
          break;

        case "checkBox":
          handleCheckBox(target);
          break;

        case "checkAll":
          handleCheckBox(target, true);
          break;

        default:
          const functionNameToCall = props[dataAction];
          if (typeof functionNameToCall === "function") {
            functionNameToCall(target.getAttribute("data-id"));
          }
          break;
      }
  };

  const { theme } = useSelector((state) => state);
  const refreshTable = theme.refreshDataTable;

  let elementsWithDataValue = document.querySelectorAll("button[data-action]");

  useEffect(() => {
    let elementsWithDataValue = document.querySelectorAll(
      "button[data-action]"
    );
    let elementsWithInputDataValue =
      document.querySelectorAll("input[data-action]");
    elementsWithDataValue.forEach((element) => {
      element.addEventListener("click", handleDataValueClick);
    });
    elementsWithInputDataValue.forEach((element) => {
      element.addEventListener("click", handleDataValueClick);
    });

    return () => {
      elementsWithDataValue.forEach((element) => {
        element.removeEventListener("click", handleDataValueClick);
      });
      elementsWithInputDataValue.forEach((element) => {
        element.removeEventListener("click", handleDataValueClick);
      });
    };
  }, [elementsWithDataValue]);

  useEffect(() => {
    const dataTable = new DataTable(tableRef.current, configureDataTable);
    return () => {
      if (dataTable) dataTable.destroy();
    };
  }, [refreshTable, additionalFilter]);

  return (
    <Table
      style={{ width: "100%" }}
      className="table-striped table-bordered border-start"
      ref={tableRef}
    />
  );
};

export default DataTables;
