import React, { useEffect } from "react";
import "./MultiRange.css";

const MultiRange = ({ min, max, amountRange }) => {
  const onExternalValueChange = (newValue) => {
    amountRange(newValue);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "multi-range-picker.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    window.onExternalValueChange = onExternalValueChange;
    return () => {
      window.onExternalValueChange = null;
    };
  }, []);

  return <div className="multiRange type1" data-min={min} data-max={max}></div>;
};

export default MultiRange;
