import moment from "moment";

// export const generateInvoiceNumber = (date) => {
//   const currentDate = moment(date).format("DDMMyyyy");
//   const currentMonth = moment(date).month(); // Get the current month (0-11)
//   const financialYear = moment(date).format("YY") - (currentMonth >= 3 ? 0 : 1); // If the current month is after March (0-indexed), use the current year; otherwise, use the previous year.
//   const nextFinancialYear = financialYear + 1;
//   const financialYearString = `${financialYear}-${nextFinancialYear}`;
//   return `IPH${financialYearString}/${currentDate}{id}`;
// };

export const generateInvoiceNumber = (date, type) => {
  const currentDate = moment(date).format("DDMMyyyy");
  const currentMonth = moment(date).month(); // Get the current month (0-11)
  const financialYear = moment(date).format("YY") - (currentMonth >= 3 ? 0 : 1); // If the current month is after March (0-indexed), use the current year; otherwise, use the previous year.
  const nextFinancialYear = financialYear + 1;
  const financialYearString = `${financialYear}-${nextFinancialYear}`;

  let invoicePrefix;
  if (moment(date).isSameOrAfter("2024-04-01")) {
    invoicePrefix = type === "invoice" ? "IPH-I" : "IPH-V"; // Use "IPH-I" prefix for dates before April 1, 2024 for invoices, and "VPH-I" for other types
  } else {
    invoicePrefix = type === "invoice" ? "IPH" : "IPH"; // Use "IPH" prefix for dates on or after April 1, 2024 for invoices, and "VPH" for other types
  }

  return `${invoicePrefix}${financialYearString}/${currentDate}{id}`;
};
