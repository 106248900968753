import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function InvoiceVoucherModal(props) {
  const { body, isShow, setIsShow } = props;
  const { open, id, requestType } = isShow;

  return (
    <>
      <Modal
        show={open}
        onHide={() => {
          setIsShow({ open: false, id: "", requestType: "" });
        }}
        size={isShow?.needTemplateChange ? "xl" : "lg"}
        aria-labelledby="example-custom-modal-styling-title"
      >
        {isShow?.needTemplateChange ? (
          <Modal.Header closeButton className="border-0">
            <Modal.Title id="contained-modal-title-vcenter">
              Voucher templates
            </Modal.Title>
          </Modal.Header>
        ) : (
          <Modal.Header closeButton className="border-0 pb-0" />
        )}
        <Modal.Body closeButton>{body}</Modal.Body>
      </Modal>
    </>
  );
}

export default InvoiceVoucherModal;
