import { FETCH_VOUCHER_SUCCESS } from "./VoucherActionTypes";

const initialState = { vouchers: [] };

export const voucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VOUCHER_SUCCESS:
      return { ...state, vouchers: action.data };

    default:
      return state;
  }
};
