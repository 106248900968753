import sessionStorage from "redux-persist/es/storage/session";

import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore,
} from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { userReducer } from "./Auth/AuthReducer";
import { invoiceReducer } from "./Invoice/InvoiceReducer";
import { themeReducer } from "./Theme/ThemeReducer";
import { cashbookReducer } from "./Cashbook/CashbookReducer";
import { RoleReducer } from "./Role/RoleReducer";
import { UsersReducer } from "./Users/UsersReducer";
import { voucherReducer } from "./voucher/VoucherReducer";
import { MediaReducer } from "./Media/MediaReducer";
const persistConfig = {
  key: "root",
  version: 1,
  storage: sessionStorage,
};

const appReducer = combineReducers({
  userInfo: userReducer,
  roles: RoleReducer,
  users: UsersReducer,
  invoices: invoiceReducer,
  vouchers: voucherReducer,
  cashbookEntries: cashbookReducer,
  theme: themeReducer,
  media: MediaReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "DESTROY_SESSION") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logger),
});

export const persistor = persistStore(store);
